import React from 'react';
import 'styles/Plans.scss';
import Trs from 'components/Trs/TrsComponent';
import logo from 'assets/SVG/logo-notario-black.svg';
import PlanCard from 'components/Plans/planCard';

const Plans = () => {
	const plans_choose = Trs({ jsonRef: "plans_choose" });
	const plans_notary = Trs({ jsonRef: "plans_notary" });
	const plans_office = Trs({ jsonRef: "plans_office" });
	const plans_public_articles_management = Trs({ jsonRef: "plans_public_articles_management" });
	const plans_public_faq_management = Trs({ jsonRef: "plans_public_faq_management" });
	const plans_chat_clients = Trs({ jsonRef: "plans_chat_clients" });
	const plans_docs_storage = Trs({ jsonRef: "plans_docs_storage" });
	const plans_docs_custom_templates = Trs({ jsonRef: "plans_docs_custom_templates" });
	const plans_docs_sign = Trs({ jsonRef: "plans_docs_sign" });
	const plans_calendar = Trs({ jsonRef: "plans_calendar" });
	const plans_office_share_clients = Trs({ jsonRef: "plans_office_share_clients" });
	const plans_office_share_docs = Trs({ jsonRef: "plans_office_share_docs" });
	const plans_office_share_events = Trs({ jsonRef: "plans_office_share_events" });
	const plans_office_members = Trs({ jsonRef: "plans_office_members" });
	const plans_one_account = Trs({ jsonRef: "plans_one_account" });
	const plans_two_accounts = Trs({ jsonRef: "plans_two_accounts" });
	const plans_quote = Trs({ jsonRef: "plans_quote" });
	const plans_data = Trs({ jsonRef: "plans_data" });
	const plans_docs_big_storage = Trs({ jsonRef: "plans_docs_big_storage" });

	const plan_notary = {
		"name": plans_notary,
		"prices": {
			"monthly": 119.99,
			"annual": 920
		},
		"currency": "€",
		"description": plans_one_account,
		"features": [
			plans_public_articles_management,
			plans_public_faq_management,
			plans_chat_clients,
			plans_docs_storage,
			plans_docs_custom_templates,
			plans_docs_sign,
			plans_calendar
		]
	};

	const plan_office = {
		"name": plans_office,
		"prices": {
			"monthly": 79.99,
			"annual": 680
		},
		"currency": "€",
		"description": plans_two_accounts,
		"features": [
			plans_public_articles_management,
			plans_public_faq_management,
			plans_chat_clients,
			plans_docs_storage,
			plans_docs_custom_templates,
			plans_docs_sign,
			plans_calendar,
			plans_office_share_clients,
			plans_office_share_docs,
			plans_office_share_events,
			plans_office_members
		]
	};

	const plan_data = {
		"name": plans_data,
		"prices": {
			"monthly": 109.99,
			"annual": 930
		},
		"currency": "€",
		"description": plans_two_accounts,
		"features": [
			plans_public_articles_management,
			plans_public_faq_management,
			plans_chat_clients,
			plans_docs_big_storage,
			plans_docs_custom_templates,
			plans_docs_sign,
			plans_calendar,
			plans_office_share_clients,
			plans_office_share_docs,
			plans_office_share_events,
			plans_office_members
		]
	}

	const handleMailTo = (subject) => {
		window.open(`mailto:notario.team@gmail.com?subject=${encodeURIComponent(subject)}`, '_self');
	};

	return (
		<div className="bg-white py-5 sm:py-32">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="flex items-center py-6 m-4">
					<div className="circle mr-4 h-20">
						<img src={logo} alt="Logo" className="logo h-20 w-auto" />
					</div>
					<h2 className="text-5xl font-bold tracking-tight text-gray-900">
						{plans_choose}
					</h2>
				</div>
				<hr />
				<div className="mx-auto text-center">
					<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
						<PlanCard plan={plan_office} />
						<PlanCard plan={plan_data} />
						<PlanCard plan={plan_notary} />
					</div>
				</div>
				<hr />
				<div className="m-10 flex justify-center">
					<button
						onClick={() => handleMailTo('Demande de devis personnalisé')}
						className="block w-[70%] rounded-md px-4 py-2 text-center text-sm font-semibold text-white bg-primary"
					>
						{plans_quote}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Plans;

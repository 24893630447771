
const env = process.env.REACT_APP_ENV || "development";
const djangoUrl = env === 'development' ? 'http://localhost:8000/' : 'http://92.113.25.24:8000/';
const djangoWsUrl = env === 'development' ? 'localhost:8000' : '92.113.25.24:8000';
const recaptchaKeyGoogle = process.env.REACT_APP_RECAPTCHA_KEY;
const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const satisfactionFormLink = process.env.REACT_APP_SATISFACTION_FORM_LINK;

/*
Maybe in the future add function that switch
config depends on env, but because our porject
is simple and doesn't require a lot of config
we keep it simple
*/

const config = {
    env,
    djangoUrl,
    djangoWsUrl,
    recaptchaKeyGoogle,
    googleMapApiKey,
    satisfactionFormLink,
};

export default config;

export function getUserPathSegment(user) {
    if (user == undefined)
        return ""
    switch (user.type) {
        case "Notary":
            return "/notary"
        case "User":
            return "/user"
        case "Client":
            return "/client"
        default:
            return ""
    }
}
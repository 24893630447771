import { Outlet } from "react-router-dom";
import Navbar from "components/Navbar/Navbar";
import NotarioFooter from "components/Footer/Footer";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import WebSocketNotificationManager from "components/WebSocket/WebSocketNotificationManager";
import LoadComponent from "components/Load/LoadComponent";
import React, { Suspense } from "react";
import config from "core/config";
import { FeedbackFish } from "@feedback-fish/react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { NavigationProvider } from "navigation/NavigationProvider ";

export function RootLayout() {
    const recaptchaKeyGoogle = config.recaptchaKeyGoogle;
    const language = useSelector((state) => state.user.language);

    useEffect(() => {
        document.documentElement.lang = language;
    }, [language]);

    return (
        <React.Fragment>
            <NavigationProvider>
                <GoogleReCaptchaProvider reCaptchaKey={recaptchaKeyGoogle}>
                    <div className="flex flex-col min-h-screen">
                        <header className="bg-white shadow-md">
                            <Navbar />
                        </header>

                        <Suspense fallback={<LoadComponent />}>
                            <main className="flex-grow border-0 focus:ring-0">
                                <WebSocketNotificationManager />
                                <Outlet />
                            </main>
                        </Suspense>

                        <footer className="w-full h-[18rem] pt-5">
                            <NotarioFooter />
                        </footer>
                    </div>
                </GoogleReCaptchaProvider>
            </NavigationProvider>
            {/* Widget Feedback Fish toujours visible en bas à droite */}
            <FeedbackFish projectId="54e51cb163840d">
                <button className="fixed bottom-20 right-5 bg-[#1A4B76] text-white text-2xl rounded-full w-12 h-12 flex items-center justify-center cursor-pointer z-50 shadow-lg hover:bg-[#155a8a]">
                    ?
                </button>
            </FeedbackFish>
        </React.Fragment>
    );
}

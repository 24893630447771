import React, { useState } from "react";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import NotificationZone from "components/NotificationZone/NotificationZone";
import Trs from "components/Trs/TrsComponent";
import { MobileMenu } from "./MobileMenu";
import { NavLink, useNavigate } from "react-router-dom";
import {
    navLinkClasses,
    generalLinks,
    clientLinks,
    notaryLinks,
} from "./AllNavLinks";
import { ModalNavBar } from "./ModalNavBar";
import { useNavigationConfirmation } from "navigation/NavigationProvider ";
import NavBarDropdown from "./NavBarDropdown";

const Navbar = () => {
    const navigate = useNavigate();
    const { confirmNavigation } = useNavigationConfirmation();
    const user = useSelector((state) => state.user.user);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const logout_success_title = Trs({ jsonRef: "logout_success_title" });
    const logout_success_text = Trs({ jsonRef: "logout_success_text" });

    function logOutUser() {
        navigate("/");
        Cookies.remove("authToken");
        window.location.reload();
        Swal.fire({
            icon: "success",
            title: logout_success_title,
            text: logout_success_text,
        });
    }

    const handleNavigation = (e, to) => {
        e.preventDefault();
        confirmNavigation(to);
    };

    return (
        <div
            className="bg-primary"
            style={{ borderBottom: "1.5px solid white" }}
        >
            <nav
                className="flex items-center justify-between md:max-auto h-16 lg:px-8"
                aria-label="Global"
            >
                {/* Logo */}
                <div className="flex ml-6 ml:ml-0 lg:ml-0 lg:flex-1">
                    <a className="-m-1.5 p-1.5">
                        <span className="sr-only">Notario</span>
                        <img
                            className="h-12 w-auto cursor-pointer"
                            src="/logo-notario-white.svg"
                            alt="Notario Logo"
                            onClick={() => navigate("/")}
                        />
                    </a>
                </div>

                {/* Burger Menu */}
                <div className="flex mr-7 lg:hidden md:order-2 md:justify-end">
                    {!user && (
                        <>
                            <NavLink
                                className={`${mobileMenuOpen
                                    ? "hidden"
                                    : "text-white hover:bg-white hover:text-primary text-[15px] font-medium leading-6 rounded-lg border-2 py-1 px-[0.6rem] mr-4 text-center"
                                    }`}
                                to={"/auth/register"}
                            >
                                <Trs jsonRef="navbar_register" />
                            </NavLink>
                        </>
                    )}
                    {user &&
                        user.type !== "Notary" &&
                        user.type !== "Client" && (
                            <NavLink
                                className={`${mobileMenuOpen
                                    ? "hidden"
                                    : "text-grey hover:text-white aria-[current=page]:text-white p-1.5 mr-6 text-sm font-medium"
                                    }`}
                                to={"/devenir-notaire/"}
                                onClick={(e) =>
                                    handleNavigation(e, "/devenir-notaire/")
                                }
                            >
                                <Trs jsonRef="navbar_become_notary" />
                            </NavLink>
                        )}
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className={`${mobileMenuOpen ? "hidden" : "inline-flex"
                            }  items-center justify-center rounded-md text-gray-700`}
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 17 14"
                        >
                            <path
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1h15M1 7h15M1 13h15"
                            />
                        </svg>
                    </button>
                </div>

                {/* Nav pour grands écrans */}
                <div className="hidden justify-center md:space-x-4 lg:flex lg:flex-grow lg:gap-x-3 lg:flex-1 lg:whitespace-nowrap">
                    {user && (
                        <>
                            {user.type === "Client" &&
                                clientLinks.map(({ path, jsonRef }, index) => (
                                    <NavLink
                                        key={index}
                                        className={navLinkClasses}
                                        to={path}
                                        onClick={(e) =>
                                            handleNavigation(e, path)
                                        }
                                    >
                                        <Trs jsonRef={jsonRef} />
                                    </NavLink>
                                ))}
                            {user.type === "Notary" &&
                                notaryLinks.map(({ path, jsonRef }, index) => (
                                    <NavLink
                                        key={index}
                                        className={navLinkClasses}
                                        to={path}
                                        onClick={(e) =>
                                            handleNavigation(e, path)
                                        }
                                    >
                                        <Trs jsonRef={jsonRef} />
                                    </NavLink>
                                ))}
                            {user.type === "User" && (
                                <NavLink
                                    className={navLinkClasses}
                                    to={"/rechercher-notaire"}
                                    onClick={(e) =>
                                        handleNavigation(
                                            e,
                                            "/rechercher-notaire"
                                        )
                                    }
                                >
                                    <Trs jsonRef="navbar_find_notary" />
                                </NavLink>
                            )}
                        </>
                    )}

                    {!user || user.type === "User" ?

                        generalLinks.map(({ path, jsonRef }, index) => (
                            <NavLink
                                key={index}
                                className={navLinkClasses}
                                to={path}
                                onClick={(e) => handleNavigation(e, path)}
                            >
                                <Trs jsonRef={jsonRef} />
                            </NavLink>
                        ))
                        :
                        <>
                            <div className="h-full">
                                <NavBarDropdown />
                            </div>
                        </>
                    }
                </div>

                {/* Boutons Log in et Register */}
                <div className="hidden items-center lg:flex lg:flex-1 lg:justify-end space-x-4">
                    {!user && (
                        <>
                            <NavLink
                                className={navLinkClasses}
                                to={"/auth/login"}
                            >
                                <Trs jsonRef="navbar_login" />
                            </NavLink>
                            <NavLink
                                className="text-white hover:bg-white hover:text-primary text-base font-medium leading-6 rounded-lg border-2 py-[0.4rem] px-[0.6rem] text-center"
                                to={"/auth/register"}
                            >
                                <Trs jsonRef="navbar_register" />
                            </NavLink>
                        </>
                    )}
                    {user != undefined && (
                        <>
                            {user.type !== "Notary" &&
                                user.type !== "Client" && (
                                    <NavLink
                                        className={`${mobileMenuOpen
                                            ? "hidden"
                                            : "text-grey hover:text-white aria-[current=page]:text-white p-1.5 text-[15px] font-medium"
                                            }`}
                                        to={"/devenir-notaire/"}
                                        onClick={(e) =>
                                            handleNavigation(
                                                e,
                                                "/devenir-notaire/"
                                            )
                                        }
                                    >
                                        <Trs jsonRef="navbar_become_notary" />
                                    </NavLink>
                                )}
                            <NotificationZone />
                            <div>
                                <button
                                    type="button"
                                    className="text-white cursor-pointer hover:bg-white hover:text-primary text-base font-medium leading-6 rounded-lg border-2 py-[0.5rem] px-[0.6rem] text-center"
                                    onClick={() => setModalOpen(!modalOpen)}
                                >
                                    <Trs jsonRef={"navbar_my_account"} />
                                </button>
                                {modalOpen && (
                                    <ModalNavBar
                                        user={user}
                                        setModalOpen={setModalOpen}
                                        logOutUser={logOutUser}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>

                {/* Menu mobile */}
                {
                    mobileMenuOpen && (
                        <MobileMenu
                            setMobileMenuOpen={setMobileMenuOpen}
                            user={user}
                            logOutUser={logOutUser}
                        />
                    )
                }
            </nav >
        </div >
    );
};

export default Navbar;




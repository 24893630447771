import { setPrevLocation } from "features/navigation/navigation-slice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const NotaryLayout = ({ children }) => {
    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (user === undefined || user.type !== "Notary")
            if (user === undefined) {
                dispatch(setPrevLocation(location));
                navigate('/auth/login');
            } else
                navigate('/');
    }, [])

    return (
        <React.Fragment>
            {user != undefined && user.type == "Notary" &&
                <React.Fragment>
                    <Outlet />
                    {children}
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default NotaryLayout
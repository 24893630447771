import React from 'react';
import 'styles/TextOverlay.scss';

const Overlay = ({ content }) => {
    return (
        <div className="overlay">
            <div className="overlay-text">{content}</div>
        </div>
    );
}

export default Overlay;

import React, { useState, useRef, useEffect } from 'react';
import Trs from 'components/Trs/TrsComponent';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { getUserPathSegment } from 'navigation/navTools';
import { generalLinks, clientLinks, notaryLinks, mobileNavLinkClasses } from './AllNavLinks';

export const MobileMenu = ({ user, setMobileMenuOpen = () => { }, logOutUser = () => { } }) => {
	const navigate = useNavigate();

	const location = useLocation();
	const previousLocation = useRef(location.pathname);

	useEffect(() => {
		if (previousLocation.current !== location.pathname) {
			setMobileMenuOpen(false);
			previousLocation.current = location.pathname;
		}
	}, [location, setMobileMenuOpen]);

	return (
		<div className="lg:hidden" role="dialog" aria-modal="true" style={{ backgroundColor: 'white', color: 'white' }}>
			<div className="fixed inset-0 z-10"></div>
			<div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10" style={{ backgroundColor: '#4E7499' }}>
				<div className="flex items-center justify-end gap-5">
					{/*<a className="-m-1.5 p-1.5">
							<span className="sr-only">Notario</span>
							<img className="h-8 w-auto" src={logo} alt="Notario Logo" />
						</a>*/}
					<button
						type="button"
						onClick={() => setMobileMenuOpen(false)}
						className="-m-2.5 rounded-md p-2.5 text-gray-700"
					>
						<span className="sr-only">Close menu</span>
						<svg className="h-7 w-7" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" aria-hidden="true">
							<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</button>
				</div>
				<div className="mt-6 flow-root">
					<div className="-my-6 divide-y text-white">
						<div className="space-y-2 py-6">
							{user && (
								<>
									{user.type === "Client" && clientLinks.map(({ path, jsonRef }, index) => (
										<NavLink key={index} className={mobileNavLinkClasses} to={path}>
											<Trs jsonRef={jsonRef} />
										</NavLink>
									))}
									{user.type === "Notary" && notaryLinks.map(({ path, jsonRef }, index) => (
										<NavLink key={index} className={mobileNavLinkClasses} to={path}>
											<Trs jsonRef={jsonRef} />
										</NavLink>
									))}
									{user.type === "User" && (
										<NavLink className={mobileNavLinkClasses} to={'/rechercher-notaire'}>
											<Trs jsonRef="navbar_find_notary" />
										</NavLink>
									)}
								</>
							)}
							{generalLinks.map(({ path, jsonRef }, index) => (
								<NavLink key={index} className={mobileNavLinkClasses} to={path}>
									<Trs jsonRef={jsonRef} />
								</NavLink>
							))}
						</div>
						<div className="flex flex-col w-full h-full pt-4">
							{!user && (
								<div className='flex justify-between w-full h-full items-center'>
									<NavLink className='justify-start text-white -mx-3 block rounded-lg px-3 py-1.5 text-base font-medium text-gray-900 hover:bg-menuHoverColor' to={'/auth/login'}>
										<Trs jsonRef="navbar_login" />
									</NavLink>
									<NavLink className='text-white hover:bg-white hover:text-secondary text-base font-medium leading-6 rounded-lg border-2 hover:border-white py-1.5 px-[0.7rem]' to={'/auth/register'}>
										<Trs jsonRef="navbar_register" />
									</NavLink>
								</div>
							)}

							{user != undefined && (
								<div className="flex flex-col items-center w-full h-full">
									<div className="flex justify-between w-full">
										{user.type !== "Notary" && user.type !== "Client" && (
											<NavLink className="hover:bg-menuHoverColor px-3 py-2 rounded-lg text-base font-medium leading-7" to={'/devenir-notaire/'}>
												<Trs jsonRef="navbar_become_notary" />
											</NavLink>
										)}

										<a className='cursor-pointer hover:bg-menuHoverColor px-3 py-2 rounded-lg text-base font-medium leading-7' onClick={() => navigate(getUserPathSegment(user) + '/profile')}>
											<Trs jsonRef={'navbar_my_account'} />
										</a>
									</div>

									<div className="flex w-full justify-center mt-4 hover:bg-menuHoverColor -mx-3 rounded-lg px-3 py-2 text-base font-medium leading-7" onClick={() => logOutUser()}>
										<button className='text-white text-base font-medium hover:text-[#af0000ab]'>
											<Trs jsonRef={"navbar_modal_logout"} />
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import 'styles/Timeline.scss';
import { allEvents } from './allEvents';
import Trs from 'components/Trs/TrsComponent';

export const TimelineComponent = () => {

  const cardTranslator = (category) => {
    return Trs({ jsonRef: `all_events.${category}` })
  }

  return (
    <>
      <VerticalTimeline
        className='timeline-line'
        lineColor='#351EA4'
      >
        {allEvents && allEvents.map((event, index) => {
          return (
            <VerticalTimelineElement
              key={event.key}
              className="vertical-timeline-element--work"
              textClassName={`timeline-container-content ${event.class}`}
              contentArrowStyle={{ borderRight: `7px solid  ${event.arrowRgb}` }}
              date={event.date}
              icon={<i className={`${event.icon ? event.icon : 'fa fa-check-circle'}`}></i>}
              iconClassName={`icon-timeline ${event.class}`}
            >
              <div className='timeline-container'>
                <div className='timeline-date-container'>
                  <p className='month-style'>{cardTranslator('card_' + event.key + '.month')}</p>
                  <p className='day-style'>{event.day}</p>
                  <p className='year-style'>{event.year}</p>
                </div>

                <div className='timeline-content-separator'></div>

                <div className='timeline-infos-container'>
                  <h1 className='title-style'>{cardTranslator('card_' + event.key + '.title')}</h1>
                  <p className='description-style'>{cardTranslator('card_' + event.key + '.description')}</p>
                </div>
              </div>
            </VerticalTimelineElement>
          )
          })
        }
      </VerticalTimeline>
    </>
  );
};

import React, { useState } from "react";
import '../../styles/Button.scss';

const ButtonWithLabelNoSubmit = ({ label, onClick, disabled }) => {

    return (
      <div className="custom-button">
        <button
          className={`${disabled ? "isDisabled" : ""}`}
          type="button"
          onClick={onClick}
          >
          {label}
        </button>
      </div>
    );
  };

export default ButtonWithLabelNoSubmit;

import React, { useEffect, useState } from 'react';
import 'styles/IncommingEvents.scss';
import api from 'service/api'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Trs from 'components/Trs/TrsComponent';
import AppointmentRequest from 'components/AppointmentRequest/AppointmentRequest';
import Swal from 'sweetalert2';

const IncommingEvents = () => {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user.user);
    const csrfToken = useSelector((state) => state.user.csrfToken);
    const [inCreate, setInCreate] = useState([false]);
    const [appointments, setAppointment] = useState([]);
    const [openSendRequestModal, setOpenSendRequestModal] = useState(false);

    const error_when_loading_datas = Trs({ jsonRef: "error_when_loading_datas" });
    const basic_error = Trs({ jsonRef: "basic_error" });
    const basic_success_title = Trs({ jsonRef: "basic_success_title" });
    const appointment_success_text = Trs({ jsonRef: "appointment_success_text" });
    const appointment_error_text = Trs({ jsonRef: "appointment_error_text" });
    const ask_app = Trs({ jsonRef: "ask_app" });

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await api.get("planning/get/", csrfToken, true);

            if (response.status !== 200) {
              throw new Error(`Error: Received status code ${response.status}`);
            }

            const MAX_DISPLAYED_EVENTS = 3; // Tu peux changer cette valeur plus tard

            const formattedData = response.data
              .slice(0, MAX_DISPLAYED_EVENTS) // Limite le nombre d'éléments à afficher
              .map(appointment => {
                const beginDate = new Date(appointment.begin);
                const endDate = new Date(appointment.end);
                const isoBeginDate = beginDate.toISOString();
                const isoEndDate = endDate.toISOString();
                const frenchOptions = { 
                  day: '2-digit', 
                  month: 'long', 
                  year: 'numeric', 
                  hour: '2-digit', 
                  minute: '2-digit', 
                  hour12: false, 
                  timeZone: 'Europe/Paris' 
                };
                let duration = 0;
                if (appointment.event_type === "AP") {
                  const diff = endDate - beginDate;
                  const minutes = Math.floor((diff / 1000) / 60);
                  duration = minutes;
                }
                return {
                  ...appointment,
                  begin: beginDate.toLocaleString('fr-FR', frenchOptions),
                  end: endDate.toLocaleString('fr-FR', frenchOptions),
                  isoBeginDate,
                  isoEndDate,
                  duration
                };
              });

            const today = new Date();
            const endOfWeek = new Date();
            endOfWeek.setDate(today.getDate() + 7);
            const filteredDates = formattedData.filter(date => {
                const dateObj = new Date(date.isoBeginDate);
                return dateObj >= today && dateObj <= endOfWeek;
            });
            setAppointment(filteredDates);
            setInCreate(true);
          } catch (error) {
            console.error(error_when_loading_datas, error);
          }
        };

        fetchData();
      }, []);

    const handleCloseSendRequestModal = () => {
      setOpenSendRequestModal(false);
    }

    const handleSubmitFormSendRequest = (formData) => {
      try {
        api.post('planning/ask/', formData, csrfToken, true)
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: basic_success_title,
                    text: appointment_success_text,
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: basic_error,
                    text: appointment_error_text,
                });
            });
      } catch (error) {
        console.log(error);
      }
    };

    const goToPage = (path) => {
      navigate(path)
    }

    const getDuration = (dateBegin, dateEnd) => {
      const begin = new Date(dateBegin)
      const end = new Date(dateEnd)
      const diff = end - begin
      const minutes = Math.floor((diff / 1000) / 60)
      return minutes
    }

    if (appointments.length === 0 && inCreate) {
        return (
          <div className='incomming-events'>
            <h2 style={{marginBottom: '15px'}}><Trs jsonRef="incomming_events_no_appointments" /></h2>
            {user != undefined && user.type == "Notary" && <button onClick={() => goToPage('/notary/mes-rendez-vous')}><Trs jsonRef="incomming_events_create_appointment" /></button>}
            {
              user != undefined && user.type == "Client" &&
              <button onClick={() => setOpenSendRequestModal(true)}>{ask_app}</button>
            }
            {user.type === 'Client' && openSendRequestModal &&
              (
                <AppointmentRequest
                    onSubmit={handleSubmitFormSendRequest}
                    onClose={handleCloseSendRequestModal}
                />
              )
            }
          </div>)
    } else if (appointments.length > 0 && inCreate) {
        return (
            <div className='incomming-events'>
              <h2><Trs jsonRef="incomming_events_upcoming_appointments" /></h2>
                <div className='rdv-list'>
                    {appointments.map((appointment, index) => {
                        return (
                            <div key={index} className='minicard-event'>
                                <h3>{appointment.name}</h3>
                                <p>{appointment.description}</p>
                                {
                                  appointment.event_type === "DL" &&
                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p><Trs jsonRef="rdv_event_dl" /></p>
                                    <p>{appointment.begin}</p>
                                  </div>
                                }
                                {
                                  appointment.event_type === "OT" &&
                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p><Trs jsonRef="rdv_event_ot" /></p>
                                    <p>{appointment.begin}</p>
                                    <p>{appointment.end}</p>
                                  </div>
                                }
                                {
                                  appointment.event_type === "AP" &&
                                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p><Trs jsonRef="rdv_event_app" /></p>
                                    <p>{appointment.begin}</p>
                                    <p>{appointment.duration} minutes</p>
                                  </div>
                                }
                            </div>
                        )
                    })}
                </div>
                {user != undefined && user.type == "Notary" && <button onClick={() => goToPage('/notary/mes-rendez-vous')}><Trs jsonRef="incomming_events_create_appointment" /></button>}
            </div>
        )
    }
}

export default IncommingEvents;

import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Trs from 'components/Trs/TrsComponent';

const AppointmentRequest = ({ preFilledReason, onSubmit, onClose }) => {
  const [available_dates, setDatesAvailable] = useState('');
  const [reason, setReason] = useState(preFilledReason || '');

  const handleSubmit = () => {
    onSubmit({ available_dates, reason });
    setDatesAvailable('');
    setReason('');
    onClose();
  };

  const your_disponibilities = Trs({ jsonRef: "rdv_your_disponibilities" });
  const reseaon_ask_appointment = Trs({ jsonRef: "rdv_ask_appointment_reseon" });

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}>
        <Typography id="modal-title" variant="h4" component="h2">
          <Trs jsonRef="rdv_ask_appointment" />
        </Typography>
        <TextField
          fullWidth
          label={your_disponibilities}
          value={available_dates}
          onChange={(e) => setDatesAvailable(e.target.value)}
          variant="outlined"
          margin="normal"
        />
        <TextField
          fullWidth
          label={reseaon_ask_appointment}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          variant="outlined"
          margin="normal"
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button onClick={onClose} variant="contained" color="primary" style={{ backgroundColor: '#351EA4' }}>
            <Trs jsonRef="rdv_close_button" />
          </Button>
          <Box sx={{ width: 16 }} />
          <Button onClick={handleSubmit} variant="contained" color="primary" style={{ backgroundColor: '#351EA4' }}>
            <Trs jsonRef="rdv_send_button" />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AppointmentRequest;

import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import 'styles/ApiLoader.scss';

const ApiLoader = () => {
    return (
        <div className="api-loader-overlay">
            <BeatLoader color="#36d7b7" size={15} margin={2} />
        </div>
    );
};

export default ApiLoader;

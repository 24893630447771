import Trs from "components/Trs/TrsComponent";
import MultiStepForm from "components/utils/MultiStepForm";
import React from "react";
import 'styles/BecomeNotary.scss'
import Swal from "sweetalert2";
import apiService from 'service/api';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationOnLeave from "components/utils/ConfirmationOnLeave";

const BecomeNotary = () => {
    const csrfToken = useSelector((state) => state.user.csrfToken);
    const navigate = useNavigate();

    const request_sent_success_title = Trs({ jsonRef: "request_sent_success_title" });
    const request_error_text = Trs({ jsonRef: "request_error_text" });
    const basic_oops = Trs({ jsonRef: "basic_oops" });


    const steps = [
        {
            fields: [
                { label: <Trs jsonRef="become_notary_description_label" />, name: 'description', type: 'textarea', placeholder: <Trs jsonRef="become_notary_description_placeholder" /> },
            ]
        },
        {
            fields: [
                { label: <Trs jsonRef="become_notary_email_label" />, name: 'email', type: 'email' },
                { label: <Trs jsonRef="become_notary_phone_label" />, name: 'phone', type: 'tel' }
            ]
        },
        {
            fields: [
                { label: <Trs jsonRef="become_notary_address_label" />, name: 'address' },
                { label: <Trs jsonRef="become_notary_city_label" />, name: 'city' },
                { label: <Trs jsonRef="become_notary_state_label" />, name: 'state' },
            ]
        }
    ];

    const sendRequest = async (data) => {
        const newData = { ...data, "is_available": false }
        try {
            const response = await apiService.post('accounts/become-notary/', newData, csrfToken, true);

            if (response.status !== 200) {
                throw new Error(`Error: Received status code ${response.status}`);
            }

            Swal.fire({
                icon: 'success',
                title: request_sent_success_title,
                showConfirmButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/")
                }
            });
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: basic_oops,
                text: request_error_text,
            });
        }
    }

    return (
        <div className="become-notary-container">
            <ConfirmationOnLeave/>
            <h2><Trs jsonRef="become_notary_title" /></h2>
            <p className="intro"><Trs jsonRef="become_notary_intro" /></p>
            <MultiStepForm steps={steps} onValidate={sendRequest} />
        </div>
    )
}

export default BecomeNotary;

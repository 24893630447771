import React from "react";
import 'styles/NotFound.scss'
import { Link } from "react-router-dom";
import Trs from "components/Trs/TrsComponent";
import imgNotFound from 'assets/images/404.png';

const NotFound = () => {

	const not_found_ooops = Trs({ jsonRef: "not_found_ooops" });

    return (
        <div id="notfound-container">
		    <div className="notfound">
				<div className="not-found-content">
					<div className="not-found-text">
						<h1>{not_found_ooops}</h1>
			    		<h2><Trs jsonRef="not_found_subtitle" /></h2>
			    		<p><Trs jsonRef="not_found_message" /></p>
			    		<Link className="not-found-link" to={"/"}>
							<Trs jsonRef="not_found_back_to_home" />
						</Link>
					</div>
					<div className="not-found-img">
						<img src={imgNotFound}/>
					</div>
				</div>
		    </div>
	    </div>
    );
}

export default NotFound;

import React, { useEffect, useState } from "react";
import "styles/App.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Cookies from "js-cookie";
import apiService from "service/api";
import { useDispatch } from "react-redux";
import {
    setToken,
    setUser,
    setLanguage,
    setNotary,
} from "features/user/user-slice";
import { RouterProvider } from "react-router-dom";
import { GlobalRouter } from "navigation/GlobalRouter";
import ApiLoader from "components/Loader/ApiLoader";
import BeatLoader from "react-spinners/BeatLoader";
import { NavigationProvider } from "navigation/NavigationProvider ";

export default function App() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true); // loading pour le login
    const [apiLoading, setApiLoading] = useState(false); // loading pour les requetes apis

    // Set the loader callback to update apiLoading state in ApiService
    useEffect(() => {
        apiService.setLoaderCallback(setApiLoading);
    }, []);

    async function fetchNotary(csrfToken, userType) {
        if (userType === "Client") {
            apiService
                .get("clients/get-notary/", csrfToken, true)
                .then((response) => {
                    dispatch(setNotary(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    async function fetchOwnNotaryInfo(csrfToken, userType) {
        if (userType === "Notary") {
            apiService
                .get("notaries/office-status/", csrfToken, true)
                .then((response) => {
                    dispatch(setNotary(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    useEffect(() => {
        setLoading(true);
        const userLanguage = navigator.language || navigator.userLanguage;
        const selectedLanguage = userLanguage.split("-")[0];
        dispatch(setLanguage(selectedLanguage));

        function checkTokenOnPageLoad() {
            const csrfToken = Cookies.get("authToken");

            if (csrfToken) {
                apiService
                    .get("accounts/user/", csrfToken, true)
                    .then((response) => {
                        if (response.status === 200) {
                            const user = response.data.user;
                            dispatch(setToken(csrfToken));
                            dispatch(setUser(user));
                            fetchNotary(csrfToken, user.type);
                            fetchOwnNotaryInfo(csrfToken, user.type);
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        Cookies.remove("authToken");
                        setLoading(false);
                    });
            } else {
                setLoading(false);
            }
        }

        checkTokenOnPageLoad();
    }, [dispatch]);

    return (
        <React.Fragment>
            {loading ? (
                <div className="initial-loader">
                    <BeatLoader color="#36d7b7" size={15} margin={2} />
                </div>
            ) : (
                <React.Fragment>
                    {apiLoading && <ApiLoader />}{" "}
                    {/* Show API loader when apiLoading is true */}
                    <RouterProvider
                        router={GlobalRouter}
                        future={{ v7_relativeSplatPath: true }}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/SectionButton.scss';
import { useIntersectionObserver } from 'components/utils/UseIntersectionObserver';

export const SectionButton = (
    {
        goToPage,
        text,
        secondStyle = false,
    }
    ) => {

    const navigate = useNavigate()

    const className = secondStyle ? 'home-btn-button-style2' : 'home-btn-button-style1';

    useIntersectionObserver('.home-btn-button-style2', 'open', {threshold: 0.2}, true);
    useIntersectionObserver('.home-btn-button-style1', 'open', {threshold: 0.2}, true);

    return (
        <div className='section-button-container'>
            <button className={className} onClick={() => navigate(goToPage)}>{text}</button>
        </div>
  );
};

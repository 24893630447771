import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Trs from "components/Trs/TrsComponent";
import apiService from 'service/api';
import 'styles/NoOfficePage.scss';
import { useSelector } from 'react-redux';

const NoOfficePage = () => {
  const csrfToken = useSelector((state) => state.user.csrfToken);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await apiService.get("office/", csrfToken, true);
            const datas = response.data;
            if ( datas.active_at === undefined || datas.active_at	 === null) {
              navigate('/notary/office/in-creation');
            } else
              navigate('/notary/office/home')

        } catch (error) {
          console.log(error)
        }
    };
    fetchData();
}, []);

  return (
    <div className="no-office-page">
      <h1><Trs jsonRef="no_office_home_title"/></h1>
      <p><Trs jsonRef="no_office_home_desc"/></p>
      <a onClick={() => { navigate('/notary/office/no/register-office') }} className="register-button">
        <Trs jsonRef="no_office_home_register"/>
      </a>
    </div>
  );
};

export default NoOfficePage;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api from 'service/api';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Swal from 'sweetalert2';
import PasswordValidator from 'password-validator';
import PasswordStrengthMeter from 'components/PasswordStrengthMeter/PasswordStrengthMeter';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { translator } from 'components/Trs/TrsComponent';
import { useNavigate } from 'react-router-dom';
import { useIntersectionObserver } from 'components/utils/UseIntersectionObserver';
import Trs from 'components/Trs/TrsComponent';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Spinner } from 'components/Loader/Spinner';
import { AuthInputs } from 'components/Input/AuthInputs';

import registerImg from 'assets/images/register.png';

// Input Phone Number Custom
import 'styles/phoneNumber.scss';
import 'react-phone-number-input/style.css'

const passwordSchema = new PasswordValidator();

passwordSchema
  .is().min(8)                                     // min 8
  .is().max(100)                                  // max 100
  .has().uppercase()                              // min 1 uppercase
  .has().lowercase()                              // min 1 lowercase
  .has().digits()                                 // min 1 digit
  .has().symbols();                               // min 1 symbol

const Register = () => {

  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();

  useIntersectionObserver('.register-page-left-container', 'active')

  const csrfToken = useSelector((state) => state.user.csrfToken);
  const [loading, setLoading] = useState(false)

  const [isPasswordPopoverOpen, setIsPasswordPopoverOpen] = useState(false);

  const basic_error = Trs({ jsonRef: "basic_error" });
  const basic_error_mark = Trs({ jsonRef: "basic_error_mark" });
  const basic_success_title = Trs({ jsonRef: "basic_success_title" });
  const basic_oops = Trs({ jsonRef: "basic_oops" });
  const name_validation_error_text = Trs({ jsonRef: "name_validation_error_text" });
  const password_requirements_error_text = Trs({ jsonRef: "password_requirements_error_text" });
  const password_mismatch_error_text = Trs({ jsonRef: "password_mismatch_error_text" });
  const age_required_error_text = Trs({ jsonRef: "age_required_error_text" });
  const age_limit_error_text = Trs({ jsonRef: "age_limit_error_text" });
  const invalid_age_error_text = Trs({ jsonRef: "invalid_age_error_text" });
  const invalid_phone_error_text = Trs({ jsonRef: "invalid_phone_error_text" });
  const account_created_success_text = Trs({ jsonRef: "account_created_success_text" });
  const generic_error_text = Trs({ jsonRef: "generic_error_text" });

  const [userData, setUserData] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    age: '',
    phone: '',
    confirm_password: ''
  });

  const [passwordCheck, setPasswordCheck] = useState({
    minLength: false,
    maxLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasDigits: false,
    hasSymbols: false
  });


  const validatePassword = (password) => {
    setPasswordCheck({
      minLength: passwordSchema.is().min(8).validate(password),
      maxLength: passwordSchema.is().max(100).validate(password),
      hasUppercase: passwordSchema.has().uppercase().validate(password),
      hasLowercase: passwordSchema.has().lowercase().validate(password),
      hasDigits: passwordSchema.has().digits().validate(password),
      hasSymbols: passwordSchema.has().symbols().validate(password)
    });
  }


  const handleChange = (event) => {
    const { id, value } = event.target;
    if (id === "password") {
      validatePassword(value);
    }
    setUserData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };


  const handlePhoneChange = (value) => {
    setUserData({ ...userData, phone: value });
  }

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const recaptchaRef = React.createRef();


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedData = {
      ...userData,
    };

    if (!executeRecaptcha) {
      return;
    }

    const recaptchaValue = await executeRecaptcha("Register");

    formattedData.recaptcha = recaptchaValue;

    const nameRegex = /^[a-zA-Zàâäéèêëïîôöùûüç' -]+$/;


    if (!nameRegex.test(userData.first_name) || !nameRegex.test(userData.last_name)) {
      Swal.fire({
        icon: 'error',
        title: basic_oops,
        text: name_validation_error_text,
      });
      return;
    }

    if (!passwordSchema.validate(userData.password)) {
      Swal.fire({
        icon: 'error',
        title: basic_oops,
        text: password_requirements_error_text,
      });
      return;
    }

    if (userData.password !== userData.confirm_password) {
      Swal.fire({
        icon: 'error',
        title: basic_oops,
        text: password_mismatch_error_text,
      });
      return;
    }

    if (userData.age === '') {
      Swal.fire({
        icon: 'error',
        title: basic_oops,
        text: age_required_error_text,
      });
      return;
    }

    if (userData.age < 18) {
      Swal.fire({
        icon: 'error',
        title: basic_oops,
        text: age_limit_error_text,
      });
      return;
    }

    if (userData.age > 100) {
      Swal.fire({
        icon: 'error',
        title: basic_oops,
        text: invalid_age_error_text,
      });
      return;
    }

    // TODO
    if (userData.phone) {
      const isValidPhoneNbr = isPossiblePhoneNumber(userData.phone)

      if (!isValidPhoneNbr) {
        Swal.fire({
          icon: 'error',
          title: basic_oops,
          text: invalid_phone_error_text,
        });
        return;
      }
    }

    setLoading(true)
    try {
      api.post('accounts/register/', formattedData, csrfToken)
        .then((_resp) => {
          setLoading(false);
          navigate('/');
          setTimeout(() => {
            Swal.fire({
              icon: 'success',
              title: basic_success_title,
              text: account_created_success_text,
            });
          }, 500);
        })
        .catch((_err) => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: basic_oops,
            text: generic_error_text,
          });
        });
    } catch (error) {
      console.log(error)
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  }

  useEffect(() => {
    document.querySelectorAll('.notario-input').forEach(function (el) {
      el.addEventListener('blur', function () {
        if (el.value.trim() != "") {
          el.classList.add('has-val')
        } else {
          el.classList.remove('has-val')
        }
      })
    })
  }, []);

  return (
    <>
      <div className="flex h-screen w-full flex-col md:flex-row p-4">
        <div style={{ margin: '2rem', backgroundColor: '#1A4B76' }} className="hidden lg:flex w-full h-50 bg-black rounded-xl items-center justify-center">
          <img src={registerImg} className="w-3/4 h-3/4" alt="Register SVG" />

        </div>

        {/* Partie droite visible sur tous les écrans */}
        <div className="flex items-center justify-center w-full lg:w-1/2">
          <div className="grid items-center h-full w-full">
            <div className="flex flex-col items-center">
              <h1 className="mt-10 font-medium text-xl sm:text-2xl">
                {translator('auth_register_title')}
              </h1>
              <p className="mt-5 text-black text-sm sm:text-lg font-light">
                {translator('auth_register_p')}
              </p>
            </div>

            <div className="flex flex-col justify-between items-center w-full md:p-10 lg:p-10">
              <form className="w-full flex flex-col justify-center space-y-4 md:space-y-6" action="#">
                <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-6">
                  <div className="relative w-full ">
                    <AuthInputs
                      id="last_name"
                      value={userData.last_name}
                      onChange={handleChange}
                      type="text"
                      toTranslate="auth_last_name"
                    />
                  </div>
                  <div className="relative w-full ">
                    <AuthInputs
                      id="first_name"
                      value={userData.first_name}
                      onChange={handleChange}
                      type="text"
                      toTranslate="auth_first_name"
                    />
                  </div>

                  <div className="relative w-full ">
                    <AuthInputs
                      id="age"
                      value={userData.age}
                      onChange={handleChange}
                      type="number"
                      toTranslate="auth_age"
                    />
                  </div>
                </div>

                <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-6">
                  <div className="relative w-full ">
                    <AuthInputs
                      id="email"
                      value={userData.email}
                      onChange={handleChange}
                      type="text"
                      toTranslate="auth_email"
                    />
                  </div>

                  <div className="relative w-full">
                    <PhoneInput
                      international={true}
                      id="phone"
                      defaultCountry="FR"
                      countries={['FR', 'AT', 'ES', 'DE', 'DK', 'BE', 'BD', 'IT', 'GR']}
                      value={userData.phone}
                      onChange={handlePhoneChange}
                      className="border-b-2 pt-[5px] border-[#adadad] outline-none"
                    />
                  </div>
                </div>
                <div className="relative w-full mb-5">
                  <AuthInputs
                    id="password"
                    value={userData.password}
                    onChange={handleChange}
                    onFocus={() => setIsPasswordPopoverOpen(true)}
                    onBlur={() => setIsPasswordPopoverOpen(false)}
                    type={showPassword ? "text" : "password"}
                    toTranslate="auth_password"
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="absolute right-2 top-4 cursor-pointer text-xl"
                  >
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                  </span>
                </div>

                <div className="w-full h-10 pb-3">
                  <span className="font-medium text-sm">{translator('auth_password_strength')}</span>
                  <PasswordStrengthMeter password={userData.password} />
                </div>

                <div className="relative w-full py-5">
                  <AuthInputs
                    id="confirm_password"
                    value={userData.confirm_password}
                    onChange={handleChange}
                    type={showConfirmPassword ? "text" : "password"}
                    toTranslate="auth_confirm_password"
                    onKeyDown={handleKeyDown}
                  />
                  <span
                    onClick={toggleConfirmPasswordVisibility}
                    className="absolute right-2 top-9 cursor-pointer text-xl"
                  >
                    {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
                  </span>
                </div>

                <div className="flex items-center justify-center w-full mt-5">
                  <button
                    disabled={loading}
                    style={{ backgroundColor: "rgb(26, 75, 118)" }}
                    className="mt-5 w-[70%] h-10 text-white font-semibold uppercase rounded-lg"
                    type="button"
                    onClick={handleSubmit}
                  >
                    {loading ? <Spinner /> : <Trs jsonRef={'auth_sign_up'} />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Register;

import en from 'translations/en.json';
import fr from 'translations/fr.json'

const translations = {
  en,
  fr,
};

export const getTranslation = (language) => {
  return translations[language] || translations.en;
};

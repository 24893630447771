import Trs from "components/Trs/TrsComponent"
import DropDownFiles from "components/DropDown/DropDownFiles"
import { useEffect, useState } from "react"



function HorizontalTimeline({ status }) {

    const [progress, setProgress] = useState(0)

    useEffect(() => {

        switch (status) {
            case 'Créer':
                setProgress(0)
                break;
            case 'En attente':
                setProgress(10)
                break;
            case 'En cours':
                setProgress(50)
                break;
            case 'Signé':
                setProgress(100)
                break;
            default:
                setProgress(0)
                break;
        }
    }, [status])


    return (
        <div className="pt-16">
            <div className="flex flex-row">
                <div className="grid justify-items-center w-[13px]">
                    <div className="bg-primary w-[3px] h-16 rounded mb-[10px]"></div>
                    <span className="relative flex h-[13px] w-[13px]">
                        <span className="animate-ping absolute inline-flex w-full h-full rounded-full bg-primary opacity-75"></span>
                        <span className="relative inline-flex rounded-full w-[13px] h-[13px] bg-primary"></span>
                    </span>
                </div>
                <div className="grid items-end mx-[10px] my-[5px]">
                    <div className="bg-gray-200 w-20 h-[3px] rounded-full">
                        <div className="bg-primary z-10 opacity-100 h-[3px] rounded-full" style={{ width: `${progress}%` }}></div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export const ClientDocumentStatus = () => {


    return (
        <div className="rounded-lg border-black border-2 w-full h-full">
            Document status
            <div className="p-4">
                Testament:
                <HorizontalTimeline status={'En cours'} />
            </div>
        </div>
    )
    /*return (
        <div className="bg-black text-white rounded-md">
            <div className="overflow-y-auto bg-secondary rounded-lg h-[calc(100vh-200px)] z-50">
                <table className="table-fixed text-white w-full">
                    <thead className="bg-grey-50 sticky top-0 z-10">
                        <tr className='bg-secondary border-b-2 '>
                            <th className="text-left py-3 px-4 font-semibold text-[15px]">{<Trs jsonRef="document_name" />}</th>
                            <th className="text-left py-3 px-4 font-semibold text-[15px]">{<Trs jsonRef="document_last_date" />}</th>
                            <th className="text-left py-3 px-4 font-semibold text-[15px]">{<Trs jsonRef="basic_status" />}</th>
                            <th className="text-left py-3 px-4 font-semibold text-[15px]">{<Trs jsonRef="document_comment" />}</th>
                            <th className="text-left py-3 px-4 font-semibold text-[15px]">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="text-[15px]">
                        {/*files.length === 0 && (
                            <tr>
                                <td className="pt-9 pl-6 text-xl font-semibold">{<Trs jsonRef="document_no_document" />}</td>
                            </tr>
                        )*/}
{/*filteredFiles.map((file) => (
                            <tr key={file.uid} className="border-t hover:bg-[#ffffff72]">
                                <td className="py-3 text-base px-4">{file.name}</td>
                                <td className="py-3 text-base px-4">{file.lastEditionDate}</td>
                                <td className={`py-3 text-base px-4 font-${file.status === 'En attente' ? 'italic' : 'normal'} font-${file.status === 'Signé' ? 'bold' : 'normal'}`}>{file.status}</td>
                                <td className="py-3 text-base px-4">{file.comment}</td>
                                <td className="py-3 px-4 h-full" onClick={() => handleToggleDropdown(file.uid)}>
                                    <DropDownFiles
                                        itemsList={dropdownActions}
                                        dropdownName="..."
                                        documentUUID={file.uid}
                                        onAction={handleAction}
                                    />
                                </td>
                            </tr>
                        ))
                    </tbody>
                </table>
            </div >
        </div>
    )*/

}
import React, { useState } from 'react';
import Step from './StepForm';
import 'styles/MultiStepForm.scss'
import Trs from 'components/Trs/TrsComponent';

const MultiStepForm = ({ steps, onValidate }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const requiredError = Trs({ jsonRef: "multi_step_form_error_required" });
    const invalidEmailError = Trs({ jsonRef: "multi_step_form_error_mail" });
    const invalidPhoneError = Trs({ jsonRef: "multi_step_form_error_phone" });

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(String(email).toLowerCase());
    };

    const validatePhone = (phone) => {
        const re = /^\+33\d{9}$/;
        return re.test(String(phone));
    };

    const validate = () => {
        const stepFields = steps[currentStep].fields;
        const newErrors = {};

        stepFields.forEach(field => {
            if (!formData[field.name]) {
                newErrors[field.name] = `${field.label} ${requiredError}`;
            } else if (field.type === 'email' && !validateEmail(formData[field.name])) {
                newErrors[field.name] = invalidEmailError;
            } else if (field.type === 'tel' && !validatePhone(formData[field.name])) {
                newErrors[field.name] = invalidPhoneError;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setErrors({
            ...errors,
            [e.target.name]: ''
        });
    };

    const nextStep = () => {
        if (validate()) {
            setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
        }
    };

    const prevStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            onValidate(formData)
        }
    };

    return (
        <form onSubmit={handleSubmit} className="multi-step-form">
            <Step fields={steps[currentStep].fields} data={formData} handleChange={handleChange} errors={errors} />
            <div className="form-navigation">
                {currentStep > 0 && <button type="button" onClick={prevStep} className="nav-button"><Trs jsonRef="multi_step_form_back" /></button>}
                {currentStep < steps.length - 1 && <button type="button" onClick={nextStep} className="nav-button"><Trs jsonRef="multi_step_form_next" /></button>}
                {currentStep === steps.length - 1 && <button type="submit" className="submit-button"><Trs jsonRef="multi_step_form_submit" /></button>}
            </div>
        </form>
    );
};

export default MultiStepForm;

import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const UnknowLayout = ({ children }) => {
    const user = useSelector((state) => state.user.user);

    return (
        <React.Fragment>
            {!user &&
                <React.Fragment>
                    <Outlet />
                    {children}
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default UnknowLayout;

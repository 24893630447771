import Trs from 'components/Trs/TrsComponent';
import React, { useState } from 'react';
import 'styles/OfficeMember.scss'

const OfficeMemberInviteForm = ({ onClose, onInvite }) => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const emptyNameError = Trs({ jsonRef: "office_member_invite_empty_name" });
    const invalidEmailError = Trs({ jsonRef: "office_member_invite_invalid_email" });

    const validateFormData = () => {
        const newErrors = {};

        if (email.trim() === '' || !isValidEmail(email)) {
            newErrors.email = invalidEmailError;
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInvite = () => {
        const isValid = validateFormData();

        if (isValid) {
            onInvite(email);
            setEmail('');
            setErrors({});
            onClose();
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <div className='invite-form-container'>
            <div className='invite-form-block'>
                <h2><Trs jsonRef="office_member_invite_member" /></h2>
                <div className='input-block'>
                    <label><Trs jsonRef="office_member_invite_email" /></label>
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    {errors.email && <span className="error">{errors.email}</span>}
                </div>
                <div className='button-container'>
                    <button className='cancel' onClick={onClose}><Trs jsonRef="office_member_invite_cancel" /></button>
                    <button className='confirm' onClick={handleInvite}><Trs jsonRef="office_member_invite_invite" /></button>
                </div>
            </div>
        </div>
    );
};

export default OfficeMemberInviteForm;

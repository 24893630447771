const ProgressBar = ({ bgColor, completed }) => {

    return (
        <div className="h-5 w-full bg-[#e0e0de] rounded-md">
            <div className={`h-full rounded-md transition-all duration-1500 ease-in-out`}
                style={{ width: `${completed}%`, textAlign: 'right', backgroundColor: bgColor }}>
            </div>
        </div >
    );
};

export default ProgressBar;
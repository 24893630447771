import Trs from "components/Trs/TrsComponent";
import ConfirmationOverlay from "components/utils/ConfirmationOverlay";
import React, { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pendingPath, setPendingPath] = useState(null);
    const sensitiveUrls = ["/notary/office/no/register-office", "/devenir-notaire/"];
    const confirmation_sentence = Trs({ jsonRef: "confirmation_on_leave" });
    const confirmation_button = Trs({ jsonRef: "basic_confirm" });
    
    const confirmNavigation = (path) => {
        if (sensitiveUrls.includes(location.pathname)) {
            setIsModalOpen(true);
            setPendingPath(path);
        } else {
            navigate(path);
        }
    };

    const handleConfirm = () => {
        setIsModalOpen(false);
        if (pendingPath) navigate(pendingPath);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setPendingPath(null);
    };

    return (
        <NavigationContext.Provider value={{ confirmNavigation }}>
            {children}
            {isModalOpen && <ConfirmationOverlay confirmSentence={confirmation_sentence} onCancel={handleCancel} onConfirm={handleConfirm} confirmButtonWord={confirmation_button}/>}
        </NavigationContext.Provider>
    );
};

export const useNavigationConfirmation = () => {
    return useContext(NavigationContext);
};

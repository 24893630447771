import { useSelector } from 'react-redux';
import InviteForm from 'components/Office/OfficeMemberInviteForm';
import OfficeMemberPermission from 'components/Office/OfficeMemberPermission';
import Trs from 'components/Trs/TrsComponent';
import ConfirmationOverlay from 'components/utils/ConfirmationOverlay';
import TextOverlay from "components/utils/TextOverlay";
import React, { useState, useEffect } from 'react';
import 'styles/OfficeMember.scss'
import Swal from 'sweetalert2'
import apiService from 'service/api';
import OfficeNavbar from 'components/Office/OfficeNavbar';
import profilePic from 'assets/SVG/profile-blue.svg';

const OfficeMember = () => {
    const csrfToken = useSelector((state) => state.user.csrfToken);
    const office = useSelector((state) => state.user.office);
    const [profiles, setProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [showInviteForm, setShowInviteForm] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isOfficeActive, setIsOfficeActive] = useState(true);

    const basic_oops = Trs({ jsonRef: "basic_oops" });
    const member_removed_success_title = Trs({ jsonRef: "member_removed_success_title" });
    const cancellation_error_text = Trs({ jsonRef: "cancellation_error_text" });
    const member_invited_success_title = Trs({ jsonRef: "member_invited_success_title" });
    const invitation_email_error_text = Trs({ jsonRef: "invitation_email_error_text" });
    const invitation_cancelled_success_title = Trs({ jsonRef: "invitation_cancelled_success_title" });
    const confirmremoveSentence = Trs({ jsonRef: "office_member_select_remove_confirm" });


    useEffect(() => {
        fetchProfiles();
    }, []);

    useEffect(() => {
        if (office !== undefined) {
            setIsOfficeActive(office.is_active)
        }
    }, [office]);

    const fetchProfiles = async () => {
        try {
            const response = await apiService.get('office/members/', csrfToken, true)
            const data = response.data;
            setProfiles(data.members);
        } catch (error) {
            console.error('Error when loading profil :', error);
        }
    };

    const handleRemoveConfirmation = async () => {
        try {
            const response = await apiService.delete(`office/member/remove/${selectedProfile.uid}/`, csrfToken, true);

            if (response.status !== 200) {
                throw new Error(`Error: Received status code ${response.status}`);
            }
            setShowConfirmation(false);
            Swal.fire({
                icon: 'success',
                title: member_removed_success_title,
                showConfirmButton: false,
                timer: 1500
            });
            const updatedProfiles = profiles.filter((profile) => profile !== selectedProfile);
            setProfiles(updatedProfiles);
            setSelectedProfile(null);
            fetchProfiles();
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: basic_oops,
                text: cancellation_error_text,
            });
        }
    };

    const handleRemoveClick = (profile) => {
        setSelectedProfile(profile)
        setShowConfirmation(true);
    };

    const handleInviteClick = () => {
        setShowInviteForm(true);
    };

    const handleInviteClose = () => {
        setShowInviteForm(false);
    };

    const handleInvite = async (email) => {
        try {
            const response = await apiService.post('office/member/invite/', { email: email }, csrfToken, true);

            if (response.status !== 200) {
                throw new Error(`Error: Received status code ${response.status}`);
            }

            Swal.fire({
                icon: 'success',
                title: member_invited_success_title,
                showConfirmButton: false,
                timer: 1500
            });
            fetchProfiles();
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: basic_oops,
                text: invitation_email_error_text,
            });
        }
    };

    const handleProfileClick = (profile) => {
        let cpyProfiles = [...profiles]
        for (let tempProfile of cpyProfiles) {
            if (profile.uid == tempProfile.uid) {
                if (tempProfile.showInfo)
                    tempProfile.showInfo = false;
                else
                    tempProfile.showInfo = true;
                break;
            }
        }
        setProfiles(cpyProfiles)
    };

    const handleCancelRemove = () => {
        setShowConfirmation(false);
    };

    const handleCancelMemberInvitation = async (profile) => {
        try {
            const response = await apiService.delete(`office/member/cancel/${profile.uid}/`, csrfToken, true);

            if (response.status !== 200) {
                throw new Error(`Error: Received status code ${response.status}`);
            }

            Swal.fire({
                icon: 'success',
                title: invitation_cancelled_success_title,
                showConfirmButton: false,
                timer: 1500
            });
            const updatedProfiles = profiles.filter((tempprofile) => tempprofile !== profile);
            setProfiles(updatedProfiles);
            setSelectedProfile(null);
            fetchProfiles();
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: basic_oops,
                text: cancellation_error_text,
            });
        }
    };

    return (
        <div className='office-member-container'>
            <OfficeNavbar current="member" />
            <div className="title-block">
                <h3><Trs jsonRef="office_member_title" /></h3>
                <p><Trs jsonRef="office_member_description" /></p>
            </div>
            <div className='office-member-content'>
                {!isOfficeActive && <TextOverlay content={"Office Désactivé"} />}
                {profiles.map((profile, index) => (
                    <div key={index} className='member-card'>
                        <div className='member-resume'>
                            {!(profile.invitation) &&
                                <React.Fragment>
                                    <img src={profilePic} alt='Profile' className='profile-pic' />
                                    <div className='member-infos'>
                                        <div className='member-specific-name-container'>
                                            <p className='member-name-in-row'>{profile.first_name} {profile.last_name} {profile.you && <>(<Trs jsonRef="office_member_you" />)</>}</p>
                                            {profile.isOwner &&
                                                <p className='member-office-owner'><Trs jsonRef="office_member_owner" /></p>
                                            }
                                        </div>
                                        <p className='member-mail-in-row'>{profile.email}</p>
                                    </div>
                                    {(profile.type === "NO" || profile.type === "OT") &&
                                        <p className='member-office-status'><Trs jsonRef="office_member_notary" /></p>
                                    }
                                    {profile.type === "CO" &&
                                        <p className='member-office-status'><Trs jsonRef="office_member_co" /></p>
                                    }
                                    {profile.type === "CL" &&
                                        <p className='member-office-status'><Trs jsonRef="office_member_cleric" /></p>
                                    }
                                    {profile.type === "SC" &&
                                        <p className='member-office-status'><Trs jsonRef="office_member_secretary" /></p>
                                    }
                                    <button className='manage-button' onClick={() => handleProfileClick(profile)}>
                                        <Trs jsonRef="office_member_select_title" />
                                    </button>
                                </React.Fragment>
                            }
                            {profile.invitation &&
                                <React.Fragment>
                                    <img src={profilePic} alt='Profile' className='profile-pic' />
                                    <div className='member-infos'>
                                        <p className='member-name-in-row'>{profile.email}</p>
                                        <p className='member-mail-in-row'><Trs jsonRef="office_member_invitation" />: {profile.invite_by}</p>
                                    </div>
                                    <button className='cancel-button' onClick={() => handleCancelMemberInvitation(profile)}>
                                        <Trs jsonRef="office_member_cancel_invitation" />
                                    </button>
                                </React.Fragment>
                            }
                        </div>
                        {profile.showInfo &&
                            <div>
                                <h3><Trs jsonRef="office_member_select_title" /></h3>
                                <OfficeMemberPermission member={profile} />
                                {!(profile.type === "OT" && profile.you) &&
                                    <div className='remove-button-container'>
                                        {!(profile.isOwner) &&
                                            <button className='remove-button' onClick={() => { handleRemoveClick(profile) }}>
                                                {!(profile.you) &&
                                                    <Trs jsonRef="office_member_select_remove" />
                                                }
                                                {profile.you && !(profile.isOwner) &&
                                                    < Trs jsonRef="office_member_select_quit" />
                                                }
                                            </button>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                ))}
                <div className='add-card' onClick={handleInviteClick}>
                    <span className='add-card-text'>+</span>
                    <Trs jsonRef="office_member_add" />
                </div>
            </div>
            {showInviteForm && (
                <InviteForm onClose={handleInviteClose} onInvite={handleInvite} />
            )}
            {showConfirmation && (
                <ConfirmationOverlay
                    confirmSentence={confirmremoveSentence}
                    onConfirm={handleRemoveConfirmation}
                    onCancel={handleCancelRemove}
                />
            )}
        </div>
    );
}

export default OfficeMember;
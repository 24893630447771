import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    prevLocation: null,
};

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setPrevLocation(state, action) {
            state.prevLocation = action.payload;
        },
    },
});

export const { setPrevLocation } = navigationSlice.actions;

export default navigationSlice.reducer;

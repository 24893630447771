import React, { useRef, useEffect, useState } from 'react';

const DropDownFiles = (
    {
        itemsList = [],
        dropdownName,
        documentUUID,
        onAction = () => { },
        disable = false,
    }) => {

    const dropdownRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (action) => {

        if (onAction) {
            onAction(documentUUID, action);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, onAction]);

    return (
        <div className="text-black relative inline-block text-left" ref={dropdownRef}>
            <div onClick={() => setIsOpen(!isOpen)}>
                <button type="button" className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" id="menu-button" aria-expanded={isOpen} aria-haspopup="true">
                    {dropdownName}
                    <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>

            {isOpen && (
                <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                    <div className="py-1" role="none">
                        {itemsList && itemsList.map((item) => (
                            <div
                                key={item.action}
                                className={`px-4 py-2 font-normal ${disable
                                        ? 'cursor-not-allowed text-[#646464]' // Disabled state: gray text, no pointer interaction
                                        : 'cursor-pointer text-black hover:bg-[#0000002e]' // Active state: black text, pointer interaction, hover effect
                                    }`}
                                aria-disabled={disable}
                                onClick={() => !disable && handleSelect(item.action)}
                            >
                                {item.label}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DropDownFiles;

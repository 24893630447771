import React, { useEffect, useState } from "react";
import { InputLabel, Select, MenuItem } from '@mui/material';
import OfficeShareClientPermission from "./OfficeShareClientPermission";
import Trs from "components/Trs/TrsComponent";
import api from 'service/api';
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const OfficeShareClientPermissionsManager = (props) => {
    const [sharedWith, setSharedWith] = useState([]);
    const [selectNotary, setSelectNotary] = useState(null);
    const csrfToken = useSelector((state) => state.user.csrfToken);
    const basic_error_mark = Trs({ jsonRef: "basic_error_mark" });
    const basic_error = Trs({ jsonRef: "basic_error" });
    const error_when_loading_clients = Trs({ jsonRef: "error_when_loading_clients" });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await api.get("notaries/get-client/" + props.client.id + "/", csrfToken, true).then((response) => {
                    if (response.status !== 200) {
                        Swal.fire({
                            icon: 'error',
                            title: basic_error_mark,
                            text: basic_error,
                        });
                    } else {
                        setSharedWith(response.data.shared_with)
                    }
                }).catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: basic_error_mark,
                        text: basic_error,
                    });
                });
            } catch (error) {
                console.error(error_when_loading_clients, error);
            }
        };
        fetchData();
    }, [])

    const handleNotaryChange = (event) => {
        const selectedValue = event.target.value;
        setSelectNotary(selectedValue === 'default' ? null : selectedValue);
    };

    return (
        <div>
            <InputLabel id="client-dropdown-label"><Trs jsonRef="office_shared_permission_select_label" /></InputLabel>
            <Select
                labelId="client-dropdown-label"
                id="client-dropdown"
                value={selectNotary ? selectNotary : 'default'}
                onChange={handleNotaryChange}
            >
                {!selectNotary && (
                    <MenuItem value="default" disabled>
                        <Trs jsonRef="office_shared_permission_select_notary_default" />
                    </MenuItem>
                )}
                {sharedWith.map((notary, index) => (
                    <MenuItem key={index} value={notary}>
                        {notary.notary}
                    </MenuItem>
                ))}
            </Select>
            {selectNotary &&
                <OfficeShareClientPermission notary={selectNotary} />
            }
        </div>
    )

}

export default OfficeShareClientPermissionsManager
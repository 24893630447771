import Trs from 'components/Trs/TrsComponent';
import React from 'react';
import 'styles/ConfirmationOverlay.scss'

const ConfirmationOverlay = ({ confirmSentence, onConfirm, onCancel, confirmButtonWord }) => {
  return (
    <div className='overlay-container'>
      <div className='confirm-block'>
        <p>{confirmSentence}</p>
        <div className="confirmation-buttons">
          <button className="cancel" onClick={onCancel}><Trs jsonRef="overlay_cancel" /></button>
          <button className="confirm" onClick={onConfirm}>{confirmButtonWord !== undefined ? confirmButtonWord : <Trs jsonRef="overlay_remove" />}</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationOverlay;

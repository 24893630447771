import React, { useEffect } from 'react';
import '../../styles/ServiceCard.scss';
import { useIntersectionObserver } from 'components/utils/UseIntersectionObserver';
import { SvgIcon } from '@mui/material';

export const ServiceCard = (
  {
    title,
	description,
	delay,
	classSVG
  }) => {

	useIntersectionObserver('.servcard-background-container', 'open', {threshold: 0.5});

  	return (
		<div className='servcard-background-container' style={{ '--animation-delay': `${delay}ms` }}>
    	  <div className='servcard-overlay-container'>
				<div className='card-img-and-title'>
					<SvgIcon component={classSVG} fontSize='large'/>
					<h1 className='card-title'>{title}</h1>
				</div>
				<div className='card-description-container'>
					<p className='card-description'>{description}</p>
				</div>
		    </div>
		</div>
  	);
};

import Trs from 'components/Trs/TrsComponent';
import SharedClientForm from 'components/Office/OfficeSharedClientForm';
import ConfirmationOverlay from 'components/utils/ConfirmationOverlay';
import TextOverlay from "components/utils/TextOverlay";
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api from 'service/api';
import Swal from 'sweetalert2';
import OfficeNavbar from 'components/Office/OfficeNavbar';
import 'styles/OfficeShareClient.scss';
import clientPic from 'assets/SVG/profile-blue.svg';
import OfficeShareClientPermissionsManager from 'components/Office/OfficeShareClientPermissionsManager'

const OfficeShareClient = () => {
    const [sharedClients, setsharedClients] = useState([]);
    const [memberList, setMemberList] = useState([]);
    const [clients, setClients] = useState([]);
    const [showShareForm, setShowShareForm] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isOfficeActive, setIsOfficeActive] = useState(true);
    const confirmRemoveSentence = Trs({ jsonRef: "office_share_client_select_remove_confirm" });

    const basic_error_mark = Trs({ jsonRef: "basic_error_mark" });
    const basic_error = Trs({ jsonRef: "basic_error" });
    const share_cancelled_success_title = Trs({ jsonRef: "share_cancelled_success_title" });
    const client_shared_success_title = Trs({ jsonRef: "client_shared_success_title" });
    const share_cancellation_error_text = Trs({ jsonRef: "share_cancellation_error_text" });
    const client_sharing_error_text = Trs({ jsonRef: "client_sharing_error_text" });
    const basic_oops = Trs({ jsonRef: "basic_oops" });
    const error_when_loading_clients = Trs({ jsonRef: "error_when_loading_clients" });
    const error_when_loading_profile = Trs({ jsonRef: "error_when_loading_profile" });

    const csrfToken = useSelector((state) => state.user.csrfToken);
    const office = useSelector((state) => state.user.office);


    useEffect(() => {
        const fetchMember = async () => {
            try {
                const response = await api.get('office/members/', csrfToken, true)
                const data = response.data;
                const tempMemberList = data.members.filter((member) => !member.you);
                setMemberList(tempMemberList);
            } catch (error) {
                console.error(error_when_loading_profile, error);
            }
        };
        const fetchClient = async () => {
            try {
                const response = await api.get("notaries/get-clients/", csrfToken, true);
                const data = response.data;
                setClients(data);
            } catch (error) {
                console.error(error_when_loading_profile, error);
            }
        };
        fetchSharedClient();
        fetchMember();
        fetchClient();
    }, []);

    useEffect(() => {
        if (office !== undefined) {
            setIsOfficeActive(office.is_active)
        }
    }, [office]);

    const fetchSharedClient = async () => {
        try {
            await api.get("notaries/get-clients/?only_not_shared=true&is_shared=true", csrfToken, true).then((response) => {
                if (response.status !== 200) {
                    Swal.fire({
                        icon: 'error',
                        title: basic_error_mark,
                        text: basic_error,
                    });
                } else {
                    setsharedClients(response.data)
                }
            }).catch((error) => {
                if (error.response.status !== 400)
                    Swal.fire({
                        icon: 'error',
                        title: basic_error_mark,
                        text: basic_error,
                    });
                else
                    Swal.fire({
                        icon: 'error',
                        title: basic_error_mark,
                        text: basic_error,
                    });
            });
        } catch (error) {
            console.error(error_when_loading_clients, error);
        }
    };

    const handleRemoveConfirmation = async () => {
        try {
            const response = await api.delete(`notaries/unshare-client/${selectedClient.id}/`, csrfToken, true);

            if (response.status !== 200) {
                throw new Error(`Error: Received status code ${response.status}`);
            }
            setShowConfirmation(false);
            Swal.fire({
                icon: 'success',
                title: share_cancelled_success_title,
                showConfirmButton: false,
                timer: 1500
            });
            fetchSharedClient();
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: basic_oops,
                text: share_cancellation_error_text,
            });
        }

        setShowConfirmation(false);
        setSelectedClient(null);
    };

    const handleClientClick = (client) => {
        let updatedclients = sharedClients.filter((tempclient) => tempclient !== client);
        if (client.showInfo)
            client.showInfo = false;
        else
            client.showInfo = true;
        updatedclients = [...updatedclients, client]
        setsharedClients(updatedclients)
    };

    const handleRemoveClick = (client) => {
        setSelectedClient(client)
        setShowConfirmation(true);
    };

    const handleShare = async (newSharedClient) => {
        try {
            const response = await api.post('notaries/share-client/', { notary: newSharedClient.with.uid, client: newSharedClient.id }, csrfToken, true);
            if (response.status !== 200) {
                throw new Error(`Error: Received status code ${response.status}`);
            }

            Swal.fire({
                icon: 'success',
                title: client_shared_success_title,
                showConfirmButton: false,
                timer: 1500
            });
            fetchSharedClient();
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: basic_oops,
                text: client_sharing_error_text,
            });
        }
    };

    const handleShareClose = () => {
        setShowShareForm(false);
    };

    const handleShareClick = () => {
        setShowShareForm(true);
    };

    const handleCancelRemove = () => {
        setShowConfirmation(false);
    };

    return (
        <div className='office-shareClient-container'>
            <OfficeNavbar current="share" />
            <div className="title-block">
                <h3><Trs jsonRef="office_client_title" /></h3>
                <p><Trs jsonRef="office_client_description" /></p>
            </div>
            <div className='office-shareClient-content'>
                {!isOfficeActive && <TextOverlay content={"Office Désactivé"} />}
                {sharedClients.map((client, index) => (
                    <div key={index} className='shareClient-card'>
                        <div className='shareClient-resume'>
                            <img src={clientPic} alt='client' className='shareClient-pic' />
                            <div className='shareClient-infos'>
                                <div className='shareClient-specific-name-container'>
                                    <p className='shareClient-name-in-row'>{client.first_name} {client.last_name}</p>
                                </div>
                                <p className='shareClient-mail-in-row'>{client.email}</p>
                            </div>
                            <button className='manage-button' onClick={() => handleClientClick(client)}>
                                <Trs jsonRef="office_member_select_title" />
                            </button>
                        </div>
                        {client.showInfo &&
                            <div>
                                <h3><Trs jsonRef="office_member_select_title" /></h3>
                                <br />
                                <OfficeShareClientPermissionsManager client={client} />
                                {!(client.isOwner && client.you) &&
                                    <div className='remove-button-container'>
                                        <button className='remove-button' onClick={() => { handleRemoveClick(client) }}>
                                            {!(client.you) &&
                                                <Trs jsonRef="office_member_select_remove" />
                                            }
                                            {client.you &&
                                                < Trs jsonRef="office_member_select_quit" />
                                            }
                                        </button>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                ))}
                <div className='add-card' onClick={handleShareClick}>
                    <span className='add-card-text'>+</span>
                    <Trs jsonRef="office_share_client_add" />
                </div>
            </div>
            {showShareForm && (
                <SharedClientForm onClose={handleShareClose} onShare={handleShare} memberList={memberList} clientList={clients} />
            )}
            {showConfirmation && (
                <ConfirmationOverlay
                    confirmSentence={confirmRemoveSentence}
                    onConfirm={handleRemoveConfirmation}
                    onCancel={handleCancelRemove}
                />
            )}
        </div>
    );
}

export default OfficeShareClient;
import React, { useState, useEffect } from "react";

const InputWithLabel = ({ label, onValueChange, value, disabled, type, name }) => {
  const [inputValue, setInputValue] = useState(value || "");
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    onValueChange(event.target.value);
  };

  return (
    <div className="profile-input-notario">
      <h2 className={`input-label-title ${isFocus ? "input-is-focus" : ""}`}>
        {label}
      </h2>
      <input
        className={`input-with-label ${disabled ? "" : "profile-button-is-focus"}`}
        disabled={disabled}
        type={type}
        name={name}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      />
    </div>
  );
};

export default InputWithLabel;

import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import Trs from 'components/Trs/TrsComponent';

const CommentSection = ({ comments, onAddComment, onDeleteComment }) => {
  const [newComment, setNewComment] = useState('');
  const authUser = useSelector((state) => state.user.user);

  const getCurrentDateTime = () => {
    const currentDateTime = new Date().toISOString();
    return currentDateTime;
  };

  const handleAddComment = () => {
    if (newComment.trim() !== '' && authUser) {
      const newCommentObject = {
        text: newComment,
        author: authUser.email,
        datetime: getCurrentDateTime(),
      };
      onAddComment(newCommentObject);
      setNewComment('');
    }
  };

  const handleDeleteComment = (commentId) => {
    onDeleteComment(commentId);
  };

  const add_a_comment = Trs({ jsonRef: "add_a_comment" });

  return (
    <div style={{ marginTop: '20px', marginBottom: '20px', padding: '20px', borderRadius: '8px', overflowWrap: 'break-word', overflow: 'auto' }}>
      <h2 style={{ marginBottom: '20px' }}>Commentaires</h2>
      <ul style={{ listStyle: 'none', padding: 0 }}>
        {comments.map((comment) => (
          <li key={comment.uid} style={{ marginBottom: '10px', display: 'flex' }}>
            <div style={{ wordWrap: 'break-word', flex: '1' }}>

              <div
                style={{
                  fontSize: '12px',
                  color: '#666',
                }}
              >
                <p style={{ margin: '0', marginBottom: '5px' }}>
                  <strong>{comment.author}:</strong> {comment.text}
                </p>
                {new Date(comment.datetime).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
                {authUser && authUser.email === comment.author &&
                  <button
                    onClick={() => handleDeleteComment(comment.uid)}
                    style={{
                      backgroundColor: 'transparent',
                      color: 'black',
                      border: 'none',
                      padding: '6px 10px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      marginRight: '10px',
                    }}
                  >
                    <i className="fas fa-trash-alt" style={{ marginRight: '5px' }}></i>
                  </button>
                }
              </div>
            </div>
          </li>
        ))}
      </ul>
      {authUser && (authUser.type === "Notary" || authUser.type === "Client") && <div style={{ marginTop: '20px' }}>
        <textarea
          rows="4"
          cols="50"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          style={{ width: '100%', marginBottom: '10px' }}
        />
        <button
          onClick={handleAddComment}
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            padding: '10px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            marginTop: '10px',
          }}
        >
          {add_a_comment}
        </button>
      </div>}
    </div>
  );
};

export default CommentSection;

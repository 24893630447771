import React from 'react';
import 'styles/AboutUs.scss';
import Trs from 'components/Trs/TrsComponent';
import TeamMember from 'pages/publicPage/TeamMember';
import { MemberCard } from 'components/HomeComponent/MemberCard';
import remiPic from 'assets/images/members-img/remi.png';
import louisPic from 'assets/images/members-img/louis.png';
import mahePic from 'assets/images/members-img/mahe.png';
import valPic from 'assets/images/members-img/val.png';
import robinPic from 'assets/images/members-img/robin.png';
import tristanPic from 'assets/images/members-img/tristan.png';
import lukasPic from 'assets/images/members-img/lukas.png';
import nicoPic from 'assets/images/members-img/nico.png';

const AboutUs = () => {
  const about_us_team_member_status = Trs({ jsonRef: "about_us_team_member_status" });
  const about_us_history_first_sentence = Trs({ jsonRef: "about_us_history_first_sentence" });
  const about_us_history_second_sentence = Trs({ jsonRef: "about_us_history_second_sentence" });
  const about_us_history_third_sentence = Trs({ jsonRef: "about_us_history_third_sentence" });
  const about_us_history_fourth_sentence = Trs({ jsonRef: "about_us_history_fourth_sentence" });
  const about_us_title_mission = Trs({ jsonRef: "about_us_title_mission" });
  const about_us_paragraph_mission = Trs({ jsonRef: "about_us_mission_text" });
  const about_us_title_values = Trs({ jsonRef: "about_us_title_values" });
  const about_us_title_innovation = Trs({ jsonRef: "about_us_title_innovation" });
  const about_us_paragraph_innovation = Trs({ jsonRef: "about_us_value1_text" });
  const about_us_title_transparency = Trs({ jsonRef: "about_us_title_transparency" });
  const about_us_paragraph_transparency = Trs({ jsonRef: "about_us_value2_text" });
  const about_us_title_customer_service = Trs({ jsonRef: "about_us_title_customer_service" });
  const about_us_paragraph_customer_service = Trs({ jsonRef: "about_us_value3_text" });
  const about_us_title_meet_us = Trs({ jsonRef: "about_us_title_meet_us" });
  const about_us_our_history_title = Trs({ jsonRef: "about_us_our_history_title" });

  const memberTranslator = (name) => {
    return Trs({ jsonRef: `members_card.${name}_position` })
  }

  return (
    <><><><section className="our-history">
      <div className="our-history-content">
        <div className="our-history-title-container">
          <div className="our-history-title-background"></div>
          <h2 className="our-history-title">{about_us_our_history_title}</h2>
        </div>
        <div className="our-history-text-container flex flex-col text-center">
          <p className="our-history-text">
            {about_us_history_first_sentence}
          </p>
          <p className="our-history-text">
            {about_us_history_second_sentence}
          </p>
          <p className="our-history-text">
            {about_us_history_third_sentence}
          </p>
          <p className="our-history-text">
            {about_us_history_fourth_sentence}
          </p>
        </div>
      </div>
    </section><section className="our-mission">
        <div className="our-mission-content">
          <h2 className="our-mission-title">{about_us_title_mission} </h2>
          <p className="our-mission-text text-center">
            {about_us_paragraph_mission}
          </p>
          <div className="our-mission-logo"></div>
        </div>
      </section></><section className="our-values">
        <div className="our-values-content">
          <h2 className="our-values-title">{about_us_title_values}</h2>
          <div className="our-values-items">
            <div className="our-values-item">
              <h3 className="our-values-item-title">{about_us_title_innovation}</h3>
              <p className="our-values-item-text">
                {about_us_paragraph_innovation}
              </p>
            </div>
            <div className="our-values-item">
              <h3 className="our-values-item-title">{about_us_title_transparency}</h3>
              <p className="our-values-item-text">
                {about_us_paragraph_transparency}
              </p>
            </div>
            <div className="our-values-item">
              <h3 className="our-values-item-title">{about_us_title_customer_service}</h3>
              <p className="our-values-item-text">
                {about_us_paragraph_customer_service}
              </p>
            </div>
          </div>
        </div>
      </section></>
      <section className='about-us-team-section'>
        <div className='team-container'>
          <h1 className='team-title'>{about_us_title_meet_us}</h1>
          <div className='team-section-carousel'>
            <div className='first-list'>
              <MemberCard title={'Valentin EYRAUD'} description={memberTranslator('val')} delay={200} img={valPic} url={'https://www.linkedin.com/in/valentin-eyraud/'} />
              <MemberCard title={'Robin LANDRAUD'} description={memberTranslator('robin')} delay={300} img={robinPic} url={'https://www.linkedin.com/in/robin-landraud/'} />
              <MemberCard title={'Louis LEBLOND'} description={memberTranslator('louis')} delay={400} img={louisPic} url={'https://www.linkedin.com/in/louisleblond/'} />
              <MemberCard title={'Mahé BEAUD'} description={memberTranslator('mahe')} delay={500} img={mahePic} url={'https://www.linkedin.com/in/mahe-beaud/'} />
            </div>
            <div className='second-list'>
              <MemberCard title={'Tristan BEAU'} description={memberTranslator('tristan')} delay={600} img={tristanPic} url={'https://www.linkedin.com/in/tristan-beau-4b7612207/'} />
              <MemberCard title={'Lukas CHIARADIA'} description={memberTranslator('lukas')} delay={700} img={lukasPic} url={'https://www.linkedin.com/in/lukas-chiaradia/'} />
              <MemberCard title={'Nicolas LAVIGNE'} description={memberTranslator('nico')} delay={800} img={nicoPic} url={'https://www.linkedin.com/in/nicolas-lavigne/'} />
              <MemberCard title={'Rémi HUGUET'} description={memberTranslator('remi')} delay={900} img={remiPic} url={'https://www.linkedin.com/in/r%C3%A9mi-huguet-346184208/'} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;

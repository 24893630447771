import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    csrfToken: "",
    user : undefined,
    language: "en",
    notary: undefined,
    office : undefined,
    language: "fr"
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken(state, action) {
            state.csrfToken = action.payload
        },
        setUser(state, action) {
            state.user = action.payload
        },
        setNotary(state, action) {
            state.notary = action.payload
        },
        mergeUser(state, action) {
            state.user = { ...state.user, ...action.payload };
        },
        setLanguage(state, action) {
            state.language = action.payload
        },
        setOffice(state, action) {
            state.office = action.payload;
        }
    }
})

export const { setToken, setUser, mergeUser, setLanguage, setNotary, setOffice } = userSlice.actions;
export default userSlice.reducer
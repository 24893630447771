import React from 'react'
import 'styles/NotaryHome.scss'
import IncommingEvents from 'components/RdvManager/IncommingEvents';
import ClientsAssociatedList from 'components/NotaryInfos/ClientsAssociatedList';
import UnreadChats from 'components/Chat/UnreadChats';
import Trs from 'components/Trs/TrsComponent';

const NotaryHome = () => {

  return (
        <div className='notary-home'>
          <h1><Trs jsonRef="notary_home_title" /></h1>
          <IncommingEvents />
          <ClientsAssociatedList />
          <UnreadChats />
        </div>
    );
}

export default NotaryHome;

import React, { useState } from "react";
import Trs from "components/Trs/TrsComponent";
import InputWithLabel from "components/Input/InputWithLabel";
import ButtonWithLabel from "components/Input/ButtonWithLabel";
import Swal from "sweetalert2";

const PasswordContent = () => {

	const [editMode, setEditMode] = useState(false);
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	const basic_success_title = Trs({ jsonRef: "basic_success_title" });
	const pwd_update_success = Trs({ jsonRef: "pwd_update_success" });

  const handlePasswordUpdate = () => {
	setEditMode(false)
	Swal.fire({
		icon: 'success',
		title: basic_success_title,
		text: pwd_update_success,
	});
  };

  return (
    <div className="profile-infos-container">
      <div style={{marginTop: '20px'}}>
        <div className="profile-informations">
			<InputWithLabel
				name={"password"}
				type={'text'}
				disabled={editMode ? false : true}
				label={<Trs jsonRef="profile_tab_password" />}
				value={''}
				onValueChange={setNewPassword}
			/>
        </div>
        <br />
        <div className="profile-informations">
        	<InputWithLabel
				name={"confirmPassword"}
				type={'text'}
				disabled={editMode ? false : true}
				label={<Trs jsonRef="password_confim_password" />}
				value={''}
				onValueChange={setConfirmNewPassword}
			/>
        </div>
        <br />
			<div className="profile-buttons" style={{display: 'flex', gap: '30px'}}>
				<ButtonWithLabel disabled={editMode ? true : false} label={<Trs jsonRef="password_edit_button" />} onClick={() => setEditMode(true)}/>
				<ButtonWithLabel disabled={editMode ? false : true} label={<Trs jsonRef="profile_save_button" />} onClick={handlePasswordUpdate}/>
        	</div>
      </div>
    </div>
  );
};

export default PasswordContent;

import React, { useState, useEffect } from "react";
import '../../../../styles/ProfileSideMenu.scss';
import { useSelector } from 'react-redux';

import Trs from "components/Trs/TrsComponent";
import { useLocation } from 'react-router-dom';

const ProfileSideMenu = ({ onTabChange }) => {
  const user = useSelector((state) => state.user.user);
  const userType = user.type
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("Profile");
  const [activeClass, setActiveClass] = useState("Profile");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get('tab');
    if (tabParam) {
      setSelectedTab(tabParam);
      setActiveClass(tabParam)
    }
  }, [location.search]);

  useEffect(() => {
    onTabChange(selectedTab);
  }, [selectedTab, onTabChange]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setActiveClass(tab);
    onTabChange(tab);
  };

  return (
    <div className="side-menu-container">
      <h1><Trs jsonRef="profile_settings_title" /></h1>
      <div className="horizontal-separation"></div>
      <div className="tab-container">
        <li className={activeClass === "Profile" ? "active-tab" : "current-tab"}
          onClick={() => handleTabClick("Profile")}>
          <div className="space-for-title">
            <i className="fas fa-user"></i>&nbsp;&nbsp;<Trs jsonRef="profile_tab_profile" />
          </div>
          <div className="border-right-active"></div>
        </li>
        <li className={activeClass === "Password" ? "active-tab" : "current-tab"}
          onClick={() => handleTabClick("Password")}>
          <div className="space-for-title">
            <i className="fas fa-lock"></i>&nbsp;&nbsp;<Trs jsonRef="profile_tab_security" />
          </div>
          <div className="border-right-active"></div>
        </li>
        {userType === 'Client' && (
        <li className={activeClass === "Certification" ? "active-tab" : "current-tab"}
          onClick={() => handleTabClick("Certification")}>
            <div className="space-for-title">
              <i className="fas fa-certificate"></i>&nbsp;&nbsp;Certification
            </div>
          <div className="border-right-active"></div>
        </li>
        )}
        {userType === 'Notary' && (
          <li className={activeClass === "DocuSignToken" ? "active-tab boder-right-active" : "current-tab"}
            onClick={() => handleTabClick("DocuSignToken")}>
            <div className="space-for-title">
              <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;DocuSign
            </div>
            <div className="border-right-active"></div>
          </li>
        )}
      </div>
    </div>
  )
};

export default ProfileSideMenu;

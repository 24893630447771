import React from 'react';
import Trs from "components/Trs/TrsComponent";

const PlanCard = ({ plan }) => {
    const plans_whats_included = Trs({ jsonRef: "plans_whats_included" });
    const plans_get_access = Trs({ jsonRef: "plans_get_access" });
    const plans_per_person = Trs({ jsonRef: "plans_per_person" });
    const plans_or_annual = Trs({ jsonRef: "plans_or_annual" });

    const handleMailTo = (subject) => {
		window.open(`mailto:notario.team@gmail.com?subject=${encodeURIComponent(subject)}`, '_self');
	};

    return (
        <div className="p-4 h-full flex">
			<div className="rounded-2xl bg-gray-50 py-10 text-center shadow-md hover:scale-105 transition-transform duration-200 ease-in-out">
				<div className="px-8">
				<p className="text-base font-semibold text-gray-600">{plan.name}</p>
				<div className="mt-6 flex items-baseline justify-center gap-x-2">
					<span className="text-5xl font-bold tracking-tight text-gray-900">{plan.prices.monthly}</span>
					<span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">{plan.currency} / {plans_per_person}</span>
				</div>
				<button
                    onClick={() => handleMailTo(`Demande d'acces à l\'${plan.name}`)}
                    className="mt-10 block w-full rounded-md bg-primary px-3 py-2 text-center text-sm font-semibold text-white"
                >
                    {plans_get_access}
                </button>
				<p className="mt-6 text-xs leading-5 text-gray-600">{plans_or_annual} {plan.prices.annual} {plan.currency} {plans_per_person}</p>
				<p className="mt-6 text-xs leading-5 text-gray-600">{plan.description}</p>
				<div className="mt-10 flex items-center gap-x-4">
					<h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">{plans_whats_included}</h4>
					<div className="h-px flex-auto bg-gray-100"></div>
				</div>
				<ul role="list" className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                    {plan.features.map((feature, index) => (
                        <li key={index} className="flex gap-x-3">
                            <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                            </svg>
                            {feature}
                        </li>
                    ))}
				</ul>
				</div>
			</div>
		</div>
    )
}

export default PlanCard;
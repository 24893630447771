import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Container, Grid, InputLabel, Input, FormControl } from '@mui/material';
import apiService from 'service/api';
import { useSelector } from 'react-redux';
import 'styles/Articles.scss';
import Swal from 'sweetalert2';
import { FormControlLabel, Checkbox } from '@mui/material';
import CommentSection from './CommentSection';
import api from 'service/api';
import Loader from 'components/Loader/Loader';
import Trs from 'components/Trs/TrsComponent';
import { fontSize } from '@mui/system';
import { create } from '@mui/material/styles/createTransitions';
import ConfirmationOnLeave from 'components/utils/ConfirmationOnLeave';

const TruncatedDescription = ({ description, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const truncatedText = isExpanded ? description : description.slice(0, maxLength);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <p>
      <b>Description :</b> {truncatedText}
      {!isExpanded && description.length > maxLength
      }...
    </p>
  );
};

const CreateArticleForm = ({ onCreate, editingArticle, onCancelEdit, fetchData }) => {

  const basic_error = Trs({ jsonRef: "basic_error" });
  const basic_error_mark = Trs({ jsonRef: "basic_error_mark" });
  const basic_success_title = Trs({ jsonRef: "basic_success_title" });
  const article_updated_success_text = Trs({ jsonRef: "article_updated_success_text" });
  const article_created_success_text = Trs({ jsonRef: "article_created_success_text" });

  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user.user);
  const csrfToken = useSelector((state) => state.user.csrfToken);

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    content: '',
    author: '',
  });

  useEffect(() => {
    if (editingArticle) {
      setFormData({
        title: editingArticle.title,
        description: editingArticle.description,
        content: editingArticle.content,
        author: user.id,
      });
    }
  }, [editingArticle]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, image: file }));
  };

  const handleAddNotification = (title, content) => {
    const newNotification = {
      title: title,
      content: content,
      type: 'OTH'
    };
    try {
      apiService.post('notification/add/', newNotification, csrfToken, true)
        .then(response => {
        })
        .catch(error => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const article_update = Trs({ jsonRef: "article_update" });
  const article_entitled = Trs({ jsonRef: "article_entitled" });
  const has_been_updated = Trs({ jsonRef: "has_been_updated" });
  const new_article_created = Trs({ jsonRef: "new_article_created" });
  const new_article_entitled = Trs({ jsonRef: "new_article_entitled" });
  const has_been_created = Trs({ jsonRef: "has_been_created" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('title', formData.title);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('content', formData.content);
      formDataToSend.append('author', user.id);
      formDataToSend.append('image', formData.image);

      if (editingArticle) {
        await apiService.put(`articles/update/${editingArticle.uid}/`, formData, csrfToken, true, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrfToken,
          },
        });
        Swal.fire({
          icon: 'success',
          title: basic_success_title,
          text: article_updated_success_text,
        });

        handleAddNotification(
          `${article_update} ${formData.title}`,
          `${article_entitled} ${formData.title} ${has_been_updated}`
        );

      } else {
        const response = await apiService.postwFile('articles/add/', formDataToSend, csrfToken, true, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrfToken,
          },
        });
        Swal.fire({
          icon: 'success',
          title: basic_success_title,
          text: article_created_success_text,
        });

        handleAddNotification(
          `${new_article_created} ${formData.title}`,
          `${new_article_entitled} ${formData.title} ${has_been_created}`
        );
      }

      onCreate();
      fetchData();
    } catch (error) {
      console.error('Error creating/editing article:', error);
    }
    setLoading(false);
  };


  const handleCancelEdit = () => {
    onCancelEdit();
  };

  const modify_article = Trs({ jsonRef: "modify_article" });
  const create_article = Trs({ jsonRef: "create_article" });
  const article_title = Trs({ jsonRef: "article_title" });
  const article_description = Trs({ jsonRef: "article_description" });
  const article_content = Trs({ jsonRef: "article_content" });
  const article_image = Trs({ jsonRef: "article_image" });
  const article_create_it = Trs({ jsonRef: "article_create_it" });
  const article_cancel_modification = Trs({ jsonRef: "article_cancel_modification" });

  return (
    <div className='p-3'>
      <ConfirmationOnLeave />
      <Container>

        <Typography variant="h4" align="center" gutterBottom style={{ marginBottom: '20px' }}>
          {editingArticle ? modify_article : create_article}
        </Typography>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={article_title}
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={article_description}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label={article_content}
                name="content"
                value={formData.content}
                onChange={handleInputChange}
                required />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="image">{article_image}</InputLabel>
                <br /><br />
                <Input type="file" id="image" accept="image/*" onChange={handleFileChange} />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', margin: '15px 0px' }}>
              <Button type="submit" variant="contained" color="primary">
                {editingArticle ? modify_article : article_create_it}
              </Button>
              {editingArticle && (
                <Button onClick={handleCancelEdit} variant="contained" color="primary" style={{ marginLeft: '10px' }}>
                  {article_cancel_modification}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
        <Loader loading={loading} />
      </Container>
    </div>

  );
};

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [notaries, setNotaries] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const csrfToken = useSelector((state) => state.user.csrfToken);

  const [editingArticle, setEditingArticle] = useState(null);
  const [authorFilter, setAuthorFilter] = useState(null);
  const [sortByRecent, setSortByRecent] = useState(true);
  const user = useSelector((state) => state.user.user);
  const userType = user !== undefined ? user.type : '';

  const basic_error_mark = Trs({ jsonRef: "basic_error_mark" });
  const basic_error = Trs({ jsonRef: "basic_error" });
  const basic_success_title = Trs({ jsonRef: "basic_success_title" });

  const comment_created_success_text = Trs({ jsonRef: "comment_created_success_text" });
  const comment_deletion_confirm_title = Trs({ jsonRef: "comment_deletion_confirm_title" });
  const comment_deletion_confirm_text = Trs({ jsonRef: "comment_deletion_confirm_text" });
  const comment_deletion_success_text = Trs({ jsonRef: "comment_deletion_success_text" });
  const comment_deletion_error_text = Trs({ jsonRef: "comment_deletion_error_text" });


  useEffect(() => {
    fetchData();
  }, [authorFilter, sortByRecent]);

  const handleAddComment = async (newComment) => {
    try {
      const data = {
        text: newComment.text,
        author: newComment.author,
        datetime: newComment.datetime,
        article: selectedArticle.uid,
      };

      const response = await apiService.post(`articles/comments/add/${selectedArticle.uid}/`, data, csrfToken, true);

      Swal.fire({
        icon: 'success',
        title: basic_success_title,
        text: comment_created_success_text,
      });

      const addedComment = response.data;

      setSelectedArticle((prevSelectedArticle) => ({
        ...prevSelectedArticle,
        comments: [...(prevSelectedArticle.comments || []), addedComment],
      }));
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };




  const handleDeleteComment = async (commentId) => {

    const confirmationResult = await Swal.fire({
      icon: 'warning',
      title: comment_deletion_confirm_title,
      text: comment_deletion_confirm_text,
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Annuler',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    });

    if (confirmationResult.isConfirmed) {
      try {
        await apiService.delete(`articles/comments/delete/${selectedArticle.uid}/${commentId}/`, csrfToken, true);

        Swal.fire({
          icon: 'success',
          title: basic_success_title,
          text: comment_deletion_success_text,
        });

        const commentsResponse = await apiService.get(`articles/comments/${selectedArticle.uid}/`);
        const updatedComments = commentsResponse.data;

        setSelectedArticle((prevSelectedArticle) => ({
          ...prevSelectedArticle,
          comments: updatedComments,
        }));
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: basic_error_mark,
          text: comment_deletion_error_text,
        });
        console.error('Error deleting comment:', error);
      }
    }
  };



  const fetchData = async () => {
    try {
      const articlesResponse = await apiService.get('articles/get/?all=true');
      const articlesData = articlesResponse.data.data;

      const articlesWithNames = articlesData.map((article) => {
        const author = article.author;
        const authorName = author
          ? `${author.first_name || 'Unknown'} ${author.last_name || 'Notary'}`
          : 'Unknown Notary';

        return {
          ...article,
          author: authorName,
        };
      });

      setArticles(articlesWithNames);
    } catch (error) {
      console.error('Error loading data:', error);
    }
  };



  const filteredArticles = articles
    .filter((article) => !authorFilter || article.author.toLowerCase().includes(authorFilter.toLowerCase()))
    .sort((a, b) => (sortByRecent ? new Date(b.created_at) - new Date(a.created_at) : new Date(a.created_at) - new Date(b.created_at)));

  const handleCreateFormToggle = () => {
    setShowCreateForm(!showCreateForm);
    setEditingArticle(null);
  };

  const article_deletion_confirm_title = Trs({ jsonRef: "article_deletion_confirm_title" });
  const article_deletion_confirm_text = Trs({ jsonRef: "article_deletion_confirm_text" });
  const article_yes_delete = Trs({ jsonRef: "article_yes_delete" });
  const article_cancel = Trs({ jsonRef: "article_cancel" });
  const article_deletion_success_text = Trs({ jsonRef: "article_deletion_success_text" });
  const article_deletion_error_text = Trs({ jsonRef: "article_deletion_error_text" });

  const handleDeleteArticle = async (articleId) => {
    const confirmationResult = await Swal.fire({
      icon: 'warning',
      title: article_deletion_confirm_title,
      text: article_deletion_confirm_text,
      showCancelButton: true,
      confirmButtonText: article_yes_delete,
      cancelButtonText: article_cancel,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    });

    if (confirmationResult.isConfirmed) {
      try {
        await apiService.delete(`articles/delete/${articleId}/`, csrfToken, true);
        setArticles((prevArticles) => prevArticles.filter((article) => article.uid !== articleId));

        Swal.fire({
          icon: 'success',
          title: basic_success_title,
          text: article_deletion_success_text,
        });
        fetchData();
      } catch (error) {
        console.error('Error deleting article:', error);

        Swal.fire({
          icon: 'error',
          title: basic_error_mark,
          text: article_deletion_error_text,
        });
      }
    }
  };


  const handleModifyArticle = (article) => {
    setEditingArticle(article);
    setShowCreateForm(true);
  };

  const handleCancelEdit = () => {
    setEditingArticle(null);
    setShowCreateForm(false);
  };

  const handleCreateSuccess = () => {
    setShowCreateForm(false);
    setEditingArticle(null);
  };

  const article_back_to_list = Trs({ jsonRef: "article_back_to_list" });
  const article_desc = Trs({ jsonRef: "article_desc" });
  const article_cont = Trs({ jsonRef: "article_cont" });
  const article_auth = Trs({ jsonRef: "article_auth" });
  const navbar_articles = Trs({ jsonRef: "navbar_articles" });
  const article_title_desc = Trs({ jsonRef: "article_title_desc" });
  const author_title = Trs({ jsonRef: "author" });
  const article_show_recent_first = Trs({ jsonRef: "article_show_recent_first" });
  const see_article = Trs({ jsonRef: "see_article" });
  const modify_article = Trs({ jsonRef: "modify_article" });
  const create_article = Trs({ jsonRef: "create_article" });
  const delete_article = Trs({ jsonRef: "delete_article" });

  return (
    <div className="w-full flex flex-col justify-center" >
      {showCreateForm ? (
        <CreateArticleForm onCreate={handleCreateSuccess} editingArticle={editingArticle} onCancelEdit={handleCancelEdit} fetchData={fetchData} />
      ) : selectedArticle ? (
        <div className=" my-6 px-[10rem]">
          <Button onClick={() => setSelectedArticle(null)} variant="contained" color="primary" style={{ marginTop: '20px' }}>
            {article_back_to_list}
          </Button>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
            <div style={{ marginRight: '20px' }}>
              {selectedArticle.image &&
                <img
                  src={`${api.baseUrl.slice(0, -1)}${selectedArticle.image}`}
                  alt={selectedArticle.title}
                  style={{ width: '100%', height: 'auto', borderRadius: '8px', display: 'block' }}
                />}
            </div>
            <div className='flex flex-col justify-between space-y-4'>
              <h1 className="font-medium" style={{ fontSize: '24px', marginBottom: '10px', color: '#333' }}>{selectedArticle.title}</h1>
              <p style={{ fontSize: '16px', marginBottom: '20px', color: '#666' }}>{article_desc} {selectedArticle.description}</p>
              <p style={{ fontSize: '16px', marginBottom: '20px', color: '#666' }}>{article_cont} {selectedArticle.content}</p>
              <p style={{ fontSize: '16px', marginBottom: '10px', color: '#666' }}>{article_auth} {selectedArticle.author}</p>
            </div>
          </div>
          <div>
            <CommentSection
              comments={selectedArticle ? selectedArticle.comments : []}
              onAddComment={handleAddComment}
              onDeleteComment={handleDeleteComment}
            />
          </div>
        </div>
      ) : (
        <div className='flex flex-col justify-center w-full'>
          <div className='articles-header'>
            <h1>{navbar_articles}</h1>
            <h2>{article_title_desc}</h2>
          </div>
          <div className='px-[2rem] sm:px-[3rem] md:px-[3rem] lg:px-[13rem]'>
            <div className="filter-container" style={{ marginLeft: '30px' }}>
              <TextField
                label={author_title}
                value={authorFilter || ''}
                onChange={(e) => setAuthorFilter(() => e.target.value)}
                variant="outlined"
                size="small"
                style={{ marginRight: '20px' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sortByRecent}
                    onChange={() => setSortByRecent((prev) => !prev)}
                    name="sortByRecent"
                    color="primary"
                  />
                }
                label={article_show_recent_first}
              />
            </div>
            {userType === 'Notary' && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Button onClick={handleCreateFormToggle} variant="contained" color="primary" style={{ marginBottom: '10px', marginLeft: '30px' }}>
                  {create_article}
                </Button>
              </div>
            )}
            <ul className="flex flex-col pb-7 items-center justify-center">
              {filteredArticles.map((article) => (
                <li key={article.uid} className="article-item">
                  {article.image &&
                    <img
                      src={`${api.baseUrl.slice(0, -1)}${article.image}`}
                      alt={article.title}
                      className="article-image"
                    />
                  }
                  <div className="article-info">
                    <h2 className="article-title">{article.title}</h2>
                    <p className="font-semibold text-ellipsis overflow-hidden ...">
                      {article.description}
                    </p>
                    <p className="article-author">Auteur: {article.author}</p>
                    <div className="article-buttons">
                      <Button
                        onClick={() => setSelectedArticle(article)}
                        variant="contained"
                        color="primary"
                        className="article-button"
                      >
                        {see_article}
                      </Button>
                      {userType === 'Notary' && (
                        <Button
                          onClick={() => handleModifyArticle(article)}
                          variant="contained"
                          color="primary"
                          className="article-button"
                        >
                          {modify_article}
                        </Button>
                      )}
                      {userType === 'Notary' && (
                        <Button
                          onClick={() => handleDeleteArticle(article.uid)}
                          variant="contained"
                          color="secondary"
                          className="article-button"
                          style={{ backgroundColor: 'red' }}
                        >
                          {delete_article}
                        </Button>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Articles;

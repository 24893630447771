import React, { useState, useEffect } from 'react';
import Trs from "components/Trs/TrsComponent";
import InputWithLabel from "components/Input/InputWithLabel";
import ButtonWithLabel from "components/Input/ButtonWithLabel";
import { useSelector } from 'react-redux';
import api from 'service/api';

const PasswordContent = () => {
	const csrfToken = useSelector((state) => state.user.csrfToken);
	const user = useSelector((state) => state.user.user);
	const userType = user.type;
	const [sentenceToWrite, setSentenceToWrite] = useState('');
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	useEffect(() => {
		checkIfTokenValid();
	}, []);

	const checkIfTokenValid = async () => {
		try {
			const response = await api.get('esign/is-token-valid/', csrfToken, true);
			setSentenceToWrite(<Trs jsonRef={'profile_docusign_valid'}/>);
			setIsButtonDisabled(true);
		} catch (error) {
			if (error.response.data.message === 'Token expired') {
				setSentenceToWrite(<Trs jsonRef={'profile_docusign_expired'}/>);
			} else if (error.response.data.message === 'Token missing') {
				setSentenceToWrite(<Trs jsonRef={'profile_docusign_missing'}/>);
			}
			console.log(error);
		}
	};

	const openLink = (filePath) => {
		const a = document.createElement('a');
		a.href = filePath;
		a.download = filePath.substring(filePath.lastIndexOf('/') + 1);
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	const handleConnectDocusign = (filePath) => {
		try {
			api.get('esign/get-access-token/', csrfToken, true)
				.then((response) => {
					openLink(response.data.auth_url);
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className="docusign-container">
			<p>{sentenceToWrite}</p>
			<div className='docusign-button'>

				{userType === 'Notary' && (
					<ButtonWithLabel
					label={<Trs jsonRef={'profile_docusign_connect'}/>}
					style={{ Right: '2px' }}
					onClick={() => handleConnectDocusign('s')}
					disabled={isButtonDisabled}
					/>
				)}
			</div>
		</div>
	);
};

export default PasswordContent;

import React from 'react';

const Step = ({ fields, data, handleChange, errors }) => (
    <div className="step">
        {fields.map((field, index) => (
            <div key={index} className="form-group">
                <label className="form-label">{field.label}</label>
                {field.type != "textarea" &&
                    <input
                        className={`form-input ${errors[field.name] ? 'error' : ''}`}
                        type={field.type || 'text'}
                        name={field.name}
                        value={data[field.name] || ''}
                        placeholder={field.placeholder || ''}
                        onChange={handleChange}
                    />}
                {field.type === "textarea" &&
                    <textarea className={`form-input ${errors[field.name] ? 'error' : ''}`} name={field.name} value={data[field.name] || ''} onChange={handleChange} />
                }
                {errors[field.name] && <p className="error-message">{errors[field.name]}</p>}
            </div>
        ))}
    </div>
);

export default Step;
import { configureStore } from "@reduxjs/toolkit";
import userReducer from 'features/user/user-slice'
import navigationReducer from 'features/navigation/navigation-slice'

export const store = configureStore({
    reducer: {
        user: userReducer,
        navigation: navigationReducer,
    },
});

export default store;
import React, { useEffect } from 'react';
import Trs from 'components/Trs/TrsComponent';

const ConfirmationOnLeave = () => {
    const confirmation_on_leave = Trs({ jsonRef: "confirmation_on_leave" });

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.returnValue = confirmation_on_leave;
            return confirmation_on_leave;
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);
};

export default ConfirmationOnLeave;

import React, { useEffect } from 'react';
import apiService from "service/api";
import Swal from 'sweetalert2';
import Trs from 'components/Trs/TrsComponent';

const WebSocketNotificationManager = () => {
    const new_notif_text = Trs({ jsonRef: "websocket_new_notif_swal"});
    useEffect(() => {
        try {
            const webSocketUrl = 'ws://' + apiService.getBaseWsUrl() + '/ws/notifications/';
            const socket = new WebSocket(webSocketUrl);
            socket.onmessage = (event) => {

                const data = JSON.parse(event.data);
                Swal.fire({
                    icon: 'info',
                    title: data.title,
                    text: new_notif_text,
                })
            };

            socket.onopen = () => {
                console.log("WebSocket connection opened");
            };

            socket.onclose = (event) => {
                console.error('WebSocket closed:', event);
            };

            return () => {
                socket.close();
            };
        } catch (error) {
            console.error(error);
        }
    }, []);

    return null;
};

export default WebSocketNotificationManager;
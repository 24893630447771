import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import apiService from 'service/api';
import Swal from 'sweetalert2';
import Trs from 'components/Trs/TrsComponent';
import 'styles/OfficeHome.scss';

const OfficeInCreation = () => {
    const [formData, setFormData] = useState({
        uid: '',
        name: '',
        email: '',
        address: '',
        phone: '',
        website: '',
        description: '',
        owner: '',
    });
    const [originalData, setOriginalData] = useState("");
    const csrfToken = useSelector((state) => state.user.csrfToken);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const office_increation_cancel_success_title = Trs({ jsonRef: "office_increation_cancel_success_title" });
    const office_increation_cancel_error_title = Trs({ jsonRef: "office_increation_cancel_error_title" });
    const error_when_loading_office_datas = Trs({ jsonRef: "error_when_loading_office_datas" });
    const error_when_removing_office = Trs({ jsonRef: "error_when_removing_office" });
    const basic_oops = Trs({ jsonRef: "basic_oops" });
    const office_increation_title = Trs({ jsonRef: "office_increation_title" });
    const office_increation_description = Trs({ jsonRef: "office_increation_description" });
    const office_increation_name = Trs({ jsonRef: "office_increation_name" });
    const office_increation_email = Trs({ jsonRef: "office_increation_email" });
    const office_increation_address = Trs({ jsonRef: "office_increation_address" });
    const office_increation_phone = Trs({ jsonRef: "office_increation_phone" });
    const office_increation_website = Trs({ jsonRef: "office_increation_website" });
    const office_increation_desc = Trs({ jsonRef: "office_increation_desc" });
    const office_increation_cancel = Trs({ jsonRef: "office_increation_cancel" });
    const emptyNameError = Trs({ jsonRef: "office_home_empty_name" });
    const emptyAdressError = Trs({ jsonRef: "office_home_empty_address" });
    const invalidEmailError = Trs({ jsonRef: "office_home_valid_email" });
    const invalidPhoneError = Trs({ jsonRef: "office_home_valid_phone" });
    const office_increation_modification_error_title = Trs({ jsonRef: "office_increation_modification_error_title" });
    const office_increation_modification_success_title = Trs({ jsonRef: "office_increation_modification_success_title" });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiService.get("office/", csrfToken, true);
                const office = response.data;
                if (office != undefined && office.active_at != undefined && office.active_at != null)
                    navigate('/notary/office/home')
                setFormData(office);
                setOriginalData(office);
            } catch (error) {
                console.error(error_when_loading_office_datas, error);
                navigate("/")
            }
        };

        fetchData();
    }, [csrfToken]);

    const isDataChanged = (newFormData) => {
        return (
            originalData.name !== newFormData.name ||
            originalData.email !== newFormData.email ||
            originalData.address !== newFormData.address ||
            originalData.phone !== newFormData.phone ||
            originalData.website !== newFormData.website ||
            originalData.description !== newFormData.description
        );
    };

    const handleCancelOffice = async () => {
        try {
            const response = await apiService.delete(`office/cancel/${formData.uid}/`, csrfToken, true);
            if (response.status !== 200) {
                throw new Error(`Error: Received status code ${response.status}`);
            }
            Swal.fire({
                icon: 'success',
                title: office_increation_cancel_success_title,
                showConfirmButton: false,
                timer: 2500,
            });
            window.location.reload();
            setTimeout(() => {
                navigate('/');
            }, 1000);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: basic_oops,
                text: office_increation_cancel_error_title,
            });
            console.error(error_when_removing_office, error);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const newErrors = { ...errors };
        const newFormData = {
            ...formData,
            [name]: value,
        }

        delete newErrors[name];
        setErrors(newErrors);

        setFormData(newFormData);

        if (isDataChanged(newFormData))
            setShowSaveButton(true);
        else
            setShowSaveButton(false);
    };

    const validateFormData = () => {
        const { name: newName, email: newEmail, phone: newPhone, address: newAdress } = formData;
        const newErrors = {};

        if (newName.trim() === '') {
            newErrors.name = emptyNameError;
        }

        if (newAdress.trim() === '') {
            newErrors.address = emptyAdressError;
        }

        if (newEmail.trim() === '' || !isValidEmail(newEmail)) {
            newErrors.email = invalidEmailError;
        }

        if (newPhone.trim() === '' || !isValidPhoneNumber(newPhone)) {
            newErrors.phone = invalidPhoneError;
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhoneNumber = (phone) => {
        const phoneRegex = /^\+.+$/;
        return phoneRegex.test(phone);
    };

    const handleSave = async () => {
        try {
            const isValid = validateFormData();
            if (isValid) {
                setOriginalData(formData);
                setShowSaveButton(false);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: basic_oops,
                    text: office_increation_modification_error_title,
                });
                handleReset();
                throw new Error(`Error: Invalid Information`);
            }
            const response = await apiService.put("office/edit/" + formData.uid + '/', {
                'uid': formData.uid,
                'owner': formData.owner,
                'name': formData.name,
                'phone': formData.phone,
                'address': formData.address,
                'email': formData.email,
                'website': formData.website,
                'description': formData.description,
            }, csrfToken
                , true, {
            });
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: office_increation_modification_success_title,
                    showConfirmButton: false,
                    timer: 3000,
                });
            } else {
                console.error("Une erreur s'est produite lors de la mise à jour des informations.");
            }
        } catch (error) {
            console.error("Une erreur s'est produite lors de la mise à jour des informations :", error);
        }
        const isValid = validateFormData();
        if (isValid) {
            setOriginalData(formData);
            setShowSaveButton(false);
        }
    };

    const handleReset = () => {
        setFormData(originalData);
        setShowSaveButton(false);
    };

    return (
        <div className="home-container">
            <div className="title-block">
                <h1>{office_increation_title}</h1>
                <p>{office_increation_description}</p>
            </div>
            <form className="form-container">
                <div className="input-block">
                    <label htmlFor="name">{office_increation_name}</label>
                    <input
                        className="input-field"
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        size={formData.name.length}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="input-block">
                    <label htmlFor="email">{office_increation_email}</label>
                    <input
                        className="input-field"
                        type="text"
                        id="email"
                        name="email"
                        value={formData.email}
                        size={formData.email.length}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="input-block">
                    <label htmlFor="address">{office_increation_address}</label>
                    <input
                        className="input-field"
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        size={formData.address.length}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="input-block">
                    <label htmlFor="phone">{office_increation_phone}</label>
                    <input
                        className="input-field"
                        type="text"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        size={formData.phone.length}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="input-block">
                    <label htmlFor="website">{office_increation_website}</label>
                    <input
                        className="input-field"
                        type="text"
                        id="website"
                        name="website"
                        value={formData.website}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="input-block">
                    <label htmlFor="description">{office_increation_desc}</label>
                    <textarea
                        className="textarea-field"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                    />
                </div>
            </form>
            <div className="button-container">
                {showSaveButton && (
                    <React.Fragment>
                        <button className="cancel" type="button" onClick={handleReset}>
                            <Trs jsonRef="office_home_reset" />
                        </button>
                        <button className="confirm" type="button" onClick={handleSave}>
                            <Trs jsonRef="office_home_save" />
                        </button>
                    </React.Fragment>
                )}
            </div>
            <div>
                <button className='cancel-ask-button' onClick={() => handleCancelOffice()}>{office_increation_cancel}</button>
            </div>
        </div>
    );
}

export default OfficeInCreation;

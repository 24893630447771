
import React from 'react';
import { translator } from 'components/Trs/TrsComponent';

import 'styles/AuthInputs.scss';


export const AuthInputs = (
    {
        id,
        type,
        value,
        toTranslate, // traductor text
        onChange = () => {},
        onKeyDown = () => {},
        onBlur = () => {},
        onFocus = () => {},
    }) => {
    return (
        <div className="wrap-input-notario focus:ring-0">
  		    <input
  		      type={type}
  		      className="notario-input focus:ring-0"
  		      id={id}
              value={value}
  		      onChange={onChange}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              onFocus={onFocus}
  		    />
            <span className="focus-input" data-placeholder={translator(toTranslate)}></span>
        </div>
    );
}

import React from 'react'
import { ClientDashboard } from './Dasboard/ClientDashboard';
import { useSelector } from 'react-redux';

const ClientHome = () => {

  const user = useSelector((state) => state.user.user);

  return (
      <div className='w-full flex flex-grow px-11 py-8'>
        <ClientDashboard clientInfo={user} />
      </div>
    );
}

export default ClientHome

import React, { useState, useEffect } from "react";
import '../../styles/Loading.scss';
import loaderSVG from "assets/SVG/loader.svg"

const style = {
    height: '100%',
    width: '100%',
}

const style2 = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
}

const LoadComponent = () => {
    return (
        <div className="loading-container" style={style}>
            <div style={style2}>
                <img style={{width: '100px'}} className="notario-loader" src={loaderSVG}/>
            </div>
        </div>
    );
}

export default LoadComponent;
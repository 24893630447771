import IncommingEvents from 'components/RdvManager/IncommingEvents';
import ClientEvent from './ClientEvent';
import NotaryAssociated from 'components/ClientInfos/NotaryAssociated';
import UnreadChats from 'components/Chat/UnreadChats';
import { ClientDocumentStatus } from './ClientDocument';

export const ClientDashboard = ({ clientInfo }) => {

    return (
        <div className="w-full flex-grow">
            <div className="flex flex-col">
                <h1 className="text-2xl font-medium">Bonjour, {(clientInfo.first_name ? clientInfo.first_name : '-') + ' ' + (clientInfo.last_name ? clientInfo.last_name : '-')}</h1>
                <p className="text-base">Bienvenue sur votre dashboard</p>
            </div>
            <div className="h-[2px] my-4 bg-secondary rounded opacity-[0.5px]"></div>
            <div className='flex flex-col justify-between md:flex-row md:justify-between md:space-x-8  py-8 lg:flex-row lg:space-x-8'>
                <div className='mb-7 md:mb-0 rounded-lg w-[17rem] flex flex-col gap-4 grow-0'>
                    <NotaryAssociated />
                </div>
                <div className='rounded-lg w-auto grow-0'>
                    <ClientEvent />
                </div>
                <div className='border-l-2 border-grey md:border-l-2'>
                    <UnreadChats />
                </div>
            </div>
            {/*<IncommingEvents />*/}
        </div>
    )

}
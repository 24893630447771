import React, { useEffect, useRef } from 'react';
import Trs from 'components/Trs/TrsComponent';
import { getUserPathSegment } from 'navigation/navTools';
import { useNavigate } from 'react-router-dom';

export const ModalNavBar = ({ user, setModalOpen = () => {}, logOutUser = () => {} }) => {

  const navigate = useNavigate();
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setModalOpen]);

  return (
    <div aria-hidden="true" className="relative inline-block text-left">
      <div ref={modalRef} className='absolute right-0 z-50 mt-[1rem] w-[15rem] origin-top-right rounded-md bg-white shadow-[0px_20px_20px_10px_#00000024]'>
        <div className="px-4 py-3">
          <span className="block text-[16px] font-semibold text-primary">{user ? (user.first_name + " " + user.last_name) : ''}</span>
          <span className="block text-[14px] mt-2 text-gray-500 truncate">{user ? user.email : ''}</span>
        </div>

        <ul>
          <div className="border-t-[2px] border-lightBlack">
            <li onClick={() => navigate(getUserPathSegment(user) + '/profile')}>
              <a
                className="cursor-pointer block px-4 py-2 text-[14px] text-gray-700 hover:bg-grey"
              >
                <Trs jsonRef={"navbar_modal_settings"}/>
              </a>
            </li>
            <form className='border-t-[2px] border-lightBlack'>
              <li>
                <button
                  type="submit"
                  onClick={logOutUser}
                  className="block w-full px-4 py-3 text-left text-base font-medium hover:text-[#AF0000]"
                >
                  <Trs jsonRef={"navbar_modal_logout"}/>
                </button>
              </li>
            </form>
          </div>
        </ul>
      </div>
    </div>
  )
}


import React from "react";
import { useSelector } from "react-redux";
import Home from 'pages/publicPage/Home';
import ClientHome from "pages/clientPage/ClientHome";
import NotaryHome from "pages/notaryPage/NotaryHome";

const HomePageSelector = () => {
    const user = useSelector((state) => state.user.user);

    const userHomePage = () => {
        if (user && user.type === "Notary") {
            return <NotaryHome />
        } else if (user && user.type === "Client") {
            return <ClientHome />
        } else {
            return <Home />
        }
    }

    return (
        <>
            {userHomePage()}
        </>
    );

}

export default HomePageSelector
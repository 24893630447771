import { useEffect } from 'react';

/*
* useIntersectionObserver
* @param {string} classToObserve - the class to observe
* @param {string} classToToggle - the class to toggle
* @param {object} options - the options for the observer
* @param {boolean} keepToggle - if true, the observer will unobserve the element when it is intersecting
* @returns {void}
*/
export function useIntersectionObserver(classToObserve, classToToggle, options = {}, keepToggle = true) {
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(classToToggle);
                } if (!keepToggle) {
                    entry.target.classList.remove(classToToggle);
                }
            });
        }, options);

        const observedElements = document.querySelectorAll(classToObserve);
        observedElements.forEach((element) => {
            observer.observe(element);
        });

        return () => {
            observedElements.forEach((element) => {
                observer.unobserve(element);
            });
        };

    }, [classToObserve, classToToggle, options]);
};

import Trs from "components/Trs/TrsComponent";
import React, { useEffect, useState } from "react";
import 'styles/OfficeMemberPermission.scss'
import OfficeAccessDenied from "./OfficeAccessDenied";
import { useSelector } from "react-redux";
import Swal from 'sweetalert2';
import api from 'service/api';

const OfficeMemberPermission = (props) => {
    const csrfToken = useSelector((state) => state.user.csrfToken);
    const user = useSelector((state) => state.user.user);
    const [permissionList, setPermissionList] = useState([]);
    const [showSave, setShowSave] = useState(false);
    const [asAccess, setAsAccess] = useState(false);

    const perm_updated_text = Trs({ jsonRef: "office_perm_updated_swal" });
    const office_error_infos_send_text = Trs({ jsonRef: "office_error_infos_send_swal" });
    const basic_oops = Trs({ jsonRef: "basic_oops" });


    useEffect(() => {
        const fetchData = () => {
            if (props.member.permissions) {
                setPermissionList(props.member.permissions);
                setAsAccess(true);
            }
        };
        fetchData();
    }, [])

    const handlePermissionChange = (name, read, write) => {
        const updatedPermissions = permissionList.map(permission => {
            if (permission.name === name) {
                if (read === false && permission.write === true)
                    write = false;
                if (write === true && permission.read === false)
                    read = true;
                return { ...permission, read, write };
            }
            return permission;
        });
        setShowSave(true)
        setPermissionList(updatedPermissions)
    }

    const updatePerms = async () => {
        try {
            const response = await api.put('office/member/edit/' + props.member.uid + "/", { permissions: permissionList }, csrfToken, true);

            if (response.status !== 200) {
                throw new Error(`Error: Received status code ${response.status}`);
            }

            Swal.fire({
                icon: 'success',
                title: perm_updated_text,
                showConfirmButton: false,
                timer: 1500
            });
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: basic_oops,
                text: office_error_infos_send_text,
            });
        }
    }

    const handleSave = (event) => {
        updatePerms();
        setShowSave(false);
    }

    return (
        <React.Fragment>
            <div>
                <h3><Trs jsonRef="office_member_permission_title" /></h3>
                {asAccess === true &&
                    <div className="office-member-permission-block-container">
                        {permissionList.map((permission, index) => (
                            <React.Fragment key={index}>
                                <p><Trs jsonRef={"office_member_permission_" + permission.name} /></p>
                                <div className="checkbox-container">
                                    <label htmlFor="readCheckbox"><Trs jsonRef="office_member_permission_read" /></label>
                                    <input disabled={props.member.you ? true : false} type="checkbox" id="readCheckbox" name="readCheckbox" checked={permission.read} onChange={(event) => { handlePermissionChange(permission.name, event.target.checked, permission.write) }} />
                                    <label htmlFor="writeCheckbox"><Trs jsonRef="office_member_permission_write" /></label>
                                    <input disabled={props.member.you ? true : false} type="checkbox" id="writeCheckbox" name="writeCheckbox" checked={permission.write} onChange={(event) => { handlePermissionChange(permission.name, permission.read, event.target.checked) }} />
                                </div>
                            </React.Fragment>
                        ))}
                        {showSave &&
                            <div className="permission-button-container">
                                <button className="permission-save-button" onClick={handleSave}><Trs jsonRef="office_member_permission_save" /></button>
                            </div>
                        }
                    </div>
                }
                {asAccess === false &&
                    <React.Fragment>
                        <OfficeAccessDenied />
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    );
}

export default OfficeMemberPermission;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProfileSideMenu from './SideMenu/ProfileSideMenu';
import ProfileContent from './SideMenu/ProfileContent';
import 'styles/Profile.scss';
import PasswordContent from './SideMenu/PasswordContent';
import CertificationContent from './SideMenu/CertificationContent';
import DocuSignTokenContent from './SideMenu/DocuSignTokenContent';
import api from 'service/api'
import Trs, { translator } from 'components/Trs/TrsComponent';

const Profile = () => {
  const user = useSelector((state) => state.user);
  const csrfToken = useSelector((state) => state.user.csrfToken);
  const [selectedTab, setSelectedTab] = useState(null)
  const [labelTab, setLabelTab] = useState('')

  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    age: '',
    email: '',
    phone: '',
    address: '',
    photo: '',
    id: '',
  });

  const error_when_loading_user_datas = Trs({ jsonRef: "error_when_loading_user_datas" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("accounts/user/", csrfToken, true);

        const userData = response.data.user;

        setUserInfo({
          firstName: userData.first_name || '',
          lastName: userData.last_name || '',
          age: userData.age || '',
          email: userData.email || '',
          phone: userData.phone || '',
          address: userData.address || '',
          photo: userData.photo || '',
          id: userData.id || ''
        });
      } catch (error) {
        console.error(error_when_loading_user_datas, error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const localData = localStorage.getItem('userInfo');
    if (localData) {
      const parsedData = JSON.parse(localData);
      setUserInfo(JSON.parse(localData));
      setUserInfo(parsedData);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }, [userInfo]);

  const handleInputChange = (e) => {
    for (const key in e) {
      const value = e[key];
      if (value !== '') {
        setUserInfo({
          ...userInfo,
          [key]: value,
        })
      }
    }
  };

  useEffect(() => {
    if (selectedTab === "Profile") {
      setLabelTab(<Trs jsonRef="profile_items_title" />)
    }
    else if (selectedTab === "Password") {
      setLabelTab(<Trs jsonRef="password_items_title" />)
    } else if (selectedTab === "DocuSignToken") {
      setLabelTab("Docusign")
    } else if (selectedTab === "Certification") {
      setLabelTab("Certification")
    }

  }, [selectedTab])

  const handleTabChange = (tab) => {
    setSelectedTab(tab)
  };

  return (
    <>
      <div className='profile-main-container'>
        <ProfileSideMenu onTabChange={handleTabChange} />
        <div className="profile-container">
          <div className='profile-title-section-container'>
            <h1 className='title-section'>
              {labelTab}
            </h1>
          </div>
          <div className='content-tab-container'>
            {selectedTab === "Profile" && (
              <div className="profile-content">
                <ProfileContent userInfos={userInfo} onSaveProfile={handleInputChange} />
              </div>
            )
            }
            {selectedTab === "Password" && (
              <div className="password-content">
                <PasswordContent />
              </div>
            )
            }
            {selectedTab === "Certification" && (
              <div className="certification-content">
                <CertificationContent />
              </div>
            )
            }
            {selectedTab === "DocuSignToken" && (
              <div className="DocuSignToken-content">
                <DocuSignTokenContent />
              </div>
            )
          }
          </div>
        </div>
      </div>
    </>
  )
};


export default Profile;

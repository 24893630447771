import React, { useState } from "react";
import '../../styles/Button.scss';

const ButtonWithLabel = ({ label, onClick, disabled, customCSS = '' }) => {

  return (
    <div className="custom-button">
      <button
        className={`${customCSS} ${disabled ? "isDisabled" : ""}`}
        type="submit"
        onClick={onClick}
        >
        {label}
      </button>
    </div>
  );
};

export default ButtonWithLabel;

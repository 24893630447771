import Trs from "components/Trs/TrsComponent";
import React from "react";
import 'styles/OfficeAccessDenied.scss'

const OfficeAccessDenied = () => {

    return (
        <React.Fragment>
            <div className="access-denied-container">
                <h1><Trs jsonRef="office_member_access_denied"></Trs></h1>
            </div>
        </React.Fragment>
    );
}

export default OfficeAccessDenied;
import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslation } from 'service/translationUtils';

const Trs = ({ jsonRef }) => {
  const language = useSelector(state => state.user.language);

  const translationData = getTranslation(language);

  if (!jsonRef || typeof jsonRef !== 'string') {
    return jsonRef;
  }
  const keys = jsonRef.split('.');
  const translation = keys.reduce((obj, key) => obj && obj[key], translationData);


  return translation || jsonRef;
};

export const translator = (category) => {
  return Trs({ jsonRef: `${category}` })
}

export default Trs;

import { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { setUser, setToken, setNotary } from "features/user/user-slice";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import api from "service/api";
import Cookies from "js-cookie";
import Trs, { translator } from "components/Trs/TrsComponent";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useIntersectionObserver } from "components/utils/UseIntersectionObserver";
import { Spinner } from "components/Loader/Spinner";
import { AuthInputs } from "components/Input/AuthInputs";
import loginImg from "assets/images/login.jpg";

export const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const prevLocation = useSelector((state) => state.navigation.prevLocation);

    let [searchParams] = useSearchParams();
    const isActivated = searchParams.get("activated");

    //const basic_error = Trs({ jsonRef: "basic_error" }); Not Use
    //const basic_error_mark = Trs({ jsonRef: "basic_error_mark" }); Not Use
    const basic_success_title = Trs({ jsonRef: "basic_success_title" });
    const account_activated_success_text = Trs({
        jsonRef: "account_activated_success_text",
    });
    const incorrect_credentials_title = Trs({
        jsonRef: "incorrect_credentials_title",
    });
    const incorrect_credentials_text = Trs({
        jsonRef: "incorrect_credentials_text",
    });

    useIntersectionObserver(".login-page-right", "active");

    useEffect(() => {
        if (isActivated === "true") {
            Swal.fire({
                icon: "success",
                title: basic_success_title,
                text: account_activated_success_text,
            });
            searchParams.delete("activated");
            navigate(".", { replace: true, search: searchParams.toString() });
        }
    }, [navigate]);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    const calcExpire = (expireDate) => {
        const receivedDate = new Date(expireDate);
        const currentDate = new Date();
        const differenceMs = receivedDate - currentDate;
        const differenceDays = differenceMs / (1000 * 60 * 60 * 24);
        let expireDay = 1;
        if (differenceDays > 0) expireDay = Math.ceil(differenceDays);
        return expireDay;
    };

    async function fetchNotary(csrfToken, userType) {
        if (userType === "Client") {
            try {
                api.get("clients/get-notary/", csrfToken, true)
                    .then((response) => {
                        dispatch(setNotary(response.data));
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.log(error);
            }
        }
    }

    const fetchUserInfo = (token) => {
        api.get("accounts/user/", token, true)
            .then((response) => {
                if (response.status === 200) {
                    const user = response.data.user;
                    dispatch(setUser(user));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    function handleSubmit(event) {
        event.preventDefault();
        let formattedData = {
            email: email,
            password: password,
            recaptcha:
                "9b3dd17e-8d72-4ea1-91e2-d285e1584a3c-8392a8e8-4b89-4f1b-8356-8ab9a2f9d4f2",
        };
        if (isChecked) {
            formattedData = { ...formattedData, remember_me: true };
        }
        setLoading(true);
        try {
            api.post("accounts/login/", formattedData)
                .then((response) => {
                    const token = response.data.token;
                    const user = response.data.user;
                    const expireDay = calcExpire(response.data.expiry);
                    dispatch(setToken(token));
                    dispatch(setUser(user));
                    fetchNotary(token, user.type);
                    Cookies.set("authToken", token, { expires: expireDay });
                    setLoading(false);
                    fetchUserInfo(token);
                    if (prevLocation !== undefined && prevLocation != null)
                        navigate(prevLocation.pathname);
                    else navigate("/");
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        title: incorrect_credentials_title,
                        text: incorrect_credentials_text,
                    });
                });
        } catch (error) {
            console.log(error);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSubmit(event);
        }
    };

    useEffect(() => {
        document.querySelectorAll(".notario-input").forEach(function (el) {
            el.addEventListener("blur", function () {
                if (el.value.trim() != "") {
                    el.classList.add("has-val");
                } else {
                    el.classList.remove("has-val");
                }
            });
        });
    }, []);

    return (
        <>
            <div className="flex h-screen w-full p-4">
                <div className="flex items-center justify-center w-full lg:w-1/2">
                    <div className="flex flex-col justify-center  gap-y-20 w-full h-full">
                        <div className="flex flex-col items-center w-full">
                            <h1 className="text-4xl font-medium mt-10 text-center">
                                {translator("auth_login_title")}
                                <span className="text-primary">
                                    Notario
                                </span>
                            </h1>
                            <p className="mt-5 text-black text-lg font-semilight text-center p-5">
                                {translator("auth_login_p")}
                            </p>
                        </div>

                        <div className="flex justify-center items-center w-full">
                            <form className="w-4/5 sm:3/5 lg:w-3/5 flex flex-col items-center">
                                <div className="relative w-full mb-8">
                                    <AuthInputs
                                        id="emailInputLogin"
                                        value={email}
                                        onChange={handleChangeEmail}
                                        type="email"
                                        toTranslate={"auth_email"}
                                    />
                                </div>
                                <div className="relative w-full mb-8">
                                    <AuthInputs
                                        onKeyDown={handleKeyDown}
                                        value={password}
                                        onChange={handleChangePassword}
                                        id="passwordInputLogin"
                                        toTranslate={"auth_password"}
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                    />
                                    <span
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                        className="absolute right-2 top-4 cursor-pointer text-xl"
                                    >
                                        {showPassword ? (
                                            <FiEyeOff />
                                        ) : (
                                            <FiEye />
                                        )}
                                    </span>
                                </div>
                                <div className="flex justify-between items-center w-full mb-10">
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            className="mr-2 h-5 w-5 rounded border-2 border-purple-700"
                                            id="customCheck1"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label
                                            htmlFor="customCheck1"
                                            className="font-semilight"
                                        >
                                            {translator("auth_stay_connected")}
                                        </label>
                                    </div>
                                    <p className="font-medium text-black">
                                        <Link
                                            to="/auth/forgotten"
                                            className="text-black font-semilight"
                                        >
                                            {translator("auth_forgot_password")}
                                        </Link>
                                    </p>
                                </div>
                                <div className="w-11/12 sm:w-10/12 mb-:2/4 lg:w-3/2">
                                    <button
                                        disabled={loading}
                                        style={{ backgroundColor: "rgb(26, 75, 118)" }}
                                        className=" mt-5 w-full h-10 text-white font-semibold uppercase rounded-lg"
                                        type="button"
                                        onClick={handleSubmit}
                                    >
                                        {loading ? (
                                            <Spinner />
                                        ) : (
                                            <Trs jsonRef="auth_sign_in" />
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {<div
                    style={{ margin: "2rem" }}
                    className="hidden lg:flex w-full h-50 bg-primary rounded-xl items-center justify-center"
                >
                    <img
                        src={loginImg}
                        alt="loginSvg"
                        className="object-contain w-3/4 h-3/4 rounded-xl"
                    />
                </div>}
            </div>
        </>
    );
};

export default Login;

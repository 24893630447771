import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import 'styles/NotaryAssociated.scss';
import Trs from 'components/Trs/TrsComponent';
import ConfirmationOverlay from 'components/utils/ConfirmationOverlay';
import Swal from 'sweetalert2';
import apiService from 'service/api';

import crossSVg from 'assets/SVG/cross.svg'

const NotaryAssociated = () => {
    const notary = useSelector((state) => state.user.notary);
    const csrfToken = useSelector((state) => state.user.csrfToken);
    const [showConfirmation, setShowConfirmation] = useState(false)
    const confirmremoveSentence = Trs({ jsonRef: "notary_associated_remove_confirm" });
    const basic_oops = Trs({ jsonRef: "basic_oops" });
    const cancellation_error_text = Trs({ jsonRef: "cancellation_error_text" });
    const notary_removed_success_title = Trs({ jsonRef: "notary_associated_removed_success_title" });
    const copied = Trs({ jsonRef: "copied" });
    const copied_text = Trs({ jsonRef: "copied_text" });
    const copied_error = Trs({ jsonRef: "copied_error" });
    const copied_error_text = Trs({ jsonRef: "copied_error_text" });

    const handleRemoveConfirmation = async () => {
        try {
            const response = await apiService.delete(`clients/dissociate/`, csrfToken, true);

            if (response.status !== 200) {
                throw new Error(`Error: Received status code ${response.status}`);
            }
            setShowConfirmation(false);
            Swal.fire({
                icon: 'success',
                title: notary_removed_success_title,
                showConfirmButton: false,
                timer: 1500
            });
            window.location.reload();
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: basic_oops,
                text: cancellation_error_text,
            });
        }
    }

    const handleDisociatedNotary = () => {
        setShowConfirmation(true)
    }

    const handleCancelRemove = () => {
        setShowConfirmation(false)
    }

    const getMail = (email) => {
        return `mailto:${email}`;
    }

    function copyStringToClipboard(str) {
        navigator.clipboard.writeText(str).then(() => {
            Swal.fire({
                icon: 'success',
                title: copied,
                text: copied_text,
            });
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: copied_error,
                text: copied_error_text,
            });
        });
    }

    return (
        <React.Fragment>
            {notary && notary.first_name &&
                <div className='flex-col text-white w-full h-fit bg-primary shadow-custom-shadow rounded items-center p-5'>
                    <div className='flex flex-row justify-between'>
                        <h1 className='text-base font-semibold'><Trs jsonRef="notary_associated_title" />:</h1>
                        <img src={crossSVg} className='cursor-pointer justify-end w-6 h-6' onClick={handleDisociatedNotary} alt='Separate Notary'/>
                    </div>
                    <div className='flex w-full flex-col justify-between space-y-9'>
                        <div className='flex justify-center mt-4'>
                            <div className='flex flex-col items-center justify-between space-y-5'>
                                {notary.photo != undefined && notary.photo !== null &&
                                    <div className='w-[140px] h-[140px] rounded-full overflow-hidden'>
                                        <img src={apiService.baseUrl.slice(0, -1) + notary.photo} alt='notary' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                    </div>
                                }
                                <h3>{notary.first_name + " " + notary.last_name}</h3>
                                <div className='flex flex-row '>
                                    <h3>{notary.email}<a href={getMail(notary.email)}></a></h3>
                                    <button
                                        className='border-none ml-3 outline-none'
                                        onClick={() => copyStringToClipboard(notary.email)}
                                    >
                                        <i className="fa fa-envelope"></i>
                                    </button>
                                </div>
                                <div className='flex flex-row '>
                                    <h3>{notary.phone} </h3>
                                    <button
                                        className='border-none ml-3 outline-none'
                                        onClick={() => copyStringToClipboard(notary.phone)}
                                    >
                                        <i className="fa fa-copy"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full h-full justify-center space-x-3'>
                            <button className='border-2 border-white p-2 rounded-md bg-primary hover:bg-white text-white hover:text-primary text-base font-semibold' onClick={handleDisociatedNotary}>
                                Send a chat
                            </button>
                        </div>
                    </div>
                </div>}
            {!notary &&
                <div className='text-white font-semibold w-full p-4 h-fit bg-primary rounded items-center'>
                    <h2><Trs jsonRef="notary_associated_title_no" />.</h2>
                </div>
            }
            {showConfirmation && (
                <ConfirmationOverlay
                    confirmSentence={confirmremoveSentence}
                    onConfirm={handleRemoveConfirmation}
                    onCancel={handleCancelRemove}
                />
            )}
        </React.Fragment>
    )
};

export default NotaryAssociated

export const allEvents = [
    {
        key: 1,
        day: '15',
        year: '2023',
        icon: 'fa-solid fa-globe',
        class: 'first',
        arrowRgb: '#351EA4'
    },
    {
        key: 2,
        day: '05',
        year: '2023',
        icon: 'fa-solid fa-code',
        class: 'second',
        arrowRgb: '#53d2c5'
    },
    {
        key: 3,
        day: '20',
        year: '2023',
        icon: 'fa-solid fa-check-circle',
        class: 'first',
        arrowRgb: '#351EA4'
    },
    {
        key: 4,
        day: '05',
        year: '2023',
        icon: 'fa-solid fa-tools',
        class: 'second',
        arrowRgb: '#53d2c5'
    },
    {
        key: 5,
        day: '13',
        year: '2024',
        icon: 'fa-solid fa-pen-fancy',
        class: 'first',
        arrowRgb: '#351EA4'
    },
    {
        key: 6,
        day: '10',
        year: '2024',
        icon: 'fa-solid fa-shield-alt',
        class: 'second',
        arrowRgb: '#53d2c5'
    }
]

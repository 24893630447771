import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Trs from "components/Trs/TrsComponent";
import Swal from 'sweetalert2';
import 'styles/RegisterOfficeForm.scss';
import api from 'service/api';
import ConfirmationOnLeave from 'components/utils/ConfirmationOnLeave';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'styles/phoneNumber.scss';
import 'react-phone-number-input/style.css'

const RegisterOfficeForm = () => {
  const csrfToken = useSelector((state) => state.user.csrfToken);
  const navigate = useNavigate();
  const swalSuccessTitle = Trs({ jsonRef: "no_office_register_swal_success" });
  const swalErrorTitle = Trs({ jsonRef: "no_office_register_swal_error" });
  const basic_error = Trs({ jsonRef: "basic_error" });
  const basic_oops = Trs({ jsonRef: "basic_oops" });
  const error_when_loading_datas = Trs({ jsonRef: "error_when_loading_datas" });
  const invalid_phone_error_text = Trs({ jsonRef: "invalid_phone_error_text" });

  const [officeData, setOfficeData] = useState({
    name: '',
    address: '',
    phone: '',
    email: '',
    website: '',
    description: '',
  });

  const isValidPhoneNumber = (phone) => {
    if (phone) {
        const isValidPhoneNbr = isPossiblePhoneNumber(phone)

        if (!isValidPhoneNbr) {
          console.error('Invalid phone number');
          Swal.fire({
            icon: 'error',
            title: basic_oops,
            text: invalid_phone_error_text,
          });
          return false;
        }
        return true;
    }
    return false;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOfficeData({ ...officeData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setOfficeData({ ...officeData, phone: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleCreateClick = async () => {
    try {
      const checkPhone = isValidPhoneNumber(officeData.phone);
      const checkMail = isValidEmail(officeData.email);
      if (!checkPhone || !checkMail) {
        throw new Error(`Error: Phone Number is not valid`);
      }
      const response = await api.post(
        "office/ask/",
        {
          'name': officeData.name,
          'address': officeData.address,
          'phone': officeData.phone,
          'email': officeData.email,
          'website': officeData.website,
          'description': officeData.description,
        },
        csrfToken,
        true,
      );
      if (response.status !== 200) {
        throw new Error(`Error: Received status code ${response.status}`);
      }
      Swal.fire({
        icon: 'success',
        title: swalSuccessTitle,
        showConfirmButton: false,
        timer: 3000,
      });
      setTimeout(() => {
        navigate('/');
      }, 1500);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: basic_error,
        text: swalErrorTitle,
      });
      console.error(error_when_loading_datas, error);
    }
  };

  return (
    <div className="register-office-form">
      <ConfirmationOnLeave/>
      <div>
        <h1><Trs jsonRef="no_office_register_title" /></h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label><Trs jsonRef="no_office_register_name" /></label>
            <input
              type="text"
              id="name"
              name="name"
              value={officeData.name}
              onChange={handleChange}
              maxLength="100"
              required
            />
          </div>

          <div className="form-group">
            <label><Trs jsonRef="no_office_register_address" /></label>
            <input
              type="text"
              id="address"
              name="address"
              value={officeData.address}
              onChange={handleChange}
              maxLength="100"
              required
            />
          </div>

          <div className="form-group">
            <label><Trs jsonRef="no_office_register_phone" /></label>
            <PhoneInput
              international={true}
              name="phone"
              defaultCountry="FR"
              countries={['FR', 'AT', 'ES', 'DE', 'DK', 'BE', 'BD', 'IT', 'GR']}
              maxLength="24"
              value={officeData.phone}
              onChange={handlePhoneChange}
              className="PhoneInputNotario"
              required
            />
          </div>

          <div className="form-group">
            <label><Trs jsonRef="no_office_register_email" /></label>
            <input
              type="email"
              id="email"
              name="email"
              value={officeData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label><Trs jsonRef="no_office_register_website" /></label>
            <input
              type="url"
              id="website"
              name="website"
              value={officeData.website}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label><Trs jsonRef="no_office_register_desc" /></label>
            <textarea
              id="description"
              name="description"
              value={officeData.description}
              onChange={handleChange}
            />
          </div>

          <button className="create-button" onClick={handleCreateClick}>
            <Trs jsonRef="no_office_register_submit" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegisterOfficeForm;

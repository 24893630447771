import React, { useEffect, useState } from 'react';
import 'styles/UnreadChats.scss';
import { useNavigate } from "react-router-dom";
import Trs from 'components/Trs/TrsComponent';
import api from 'service/api';
import { useSelector } from 'react-redux';
import { formatDate } from 'date-fns';

const UnreadChats = () => {

    const userStatus = useSelector((state) => state.user.user.type);
    const csrfToken = useSelector((state) => state.user.csrfToken);
    const navigate = useNavigate();
    const [unreadChats, setUnreadChats] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const go_to_chat = Trs({ jsonRef: "go_to_chat" });

    useEffect(() => {
        const fetchUnreadChats = async () => {
            try {
                const response = await api.get('chat/get/unread/', csrfToken, true);
                setUnreadChats(response.data);
                setIsLoaded(true);
            } catch (error) {
                console.log(error);
            }
        }
        fetchUnreadChats();
    }, []);

    const goToPage = (path, data) => {
        navigate(path, data);
    }

    const formatDate = (date) => {
        const newDate = new Date(date);
        const day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
        const month = newDate.getMonth() <= 10 ? newDate.getMonth() + 1 : '0' + (newDate.getMonth() + 1);
        const year = newDate.getFullYear();
        const hour = newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours();
        const minutes = newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes();
        return `${day}/${month}/${year} ${hour}:${minutes}`;
    }

    return (
        <div className='unread-chats-container'>
            {
                isLoaded && unreadChats.length == 0 ? <h2 className='font-normal text-xl text-center pl-8'>{<Trs jsonRef="no_new_msg" />}</h2> :
                    <div>
                        <h2 className='font-normal text-xl text-center pl-8'>{<Trs jsonRef="new_msg" />} {unreadChats && (unreadChats.length == 1 ? unreadChats.length + " message" : unreadChats.length + " messages")}</h2>
                        <div className='unread-chats'>
                            {unreadChats.map((chat, index) => (
                                <div key={index} className='w-full h-full'>
                                    <span className='relative w-5 h-5 top-5 left-[96%]'>
                                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
                                        <span className="relative inline-flex rounded-full h-5 w-5 bg-primary"></span>
                                    </span>
                                    <div className='unread-chat'>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px' }}>
                                            {
                                                chat.user_photo === null ?
                                                    null :
                                                    <img className='object-cover' src={`${api.baseUrl.slice(0, -1)}${chat.user_photo}`} alt='user' />
                                            }
                                            <div className='flex flex-col'>

                                                <p><Trs jsonRef="from" />: {chat.user_name}</p>
                                                <p>{formatDate(chat.last_message)}</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-center w-full'>
                                            <button className='font-medium' onClick={() => goToPage('/' + userStatus + '/chat', { state: { user_id: chat.user_uid } })}>{go_to_chat}</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
            }
        </div>
    )
}

export default UnreadChats;
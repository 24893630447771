import React, { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

import Plans from "pages/publicPage/Plans";
import AboutUs from "pages/publicPage/AboutUs";
import Articles from "pages/publicPage/Articles";

import ForgotPassword from 'pages/publicPage/Authentification/ForgotPassword';
import ResetPassword from 'pages/publicPage/Authentification/ResetPassword';
import Profile from 'pages/clientPage/Profile/Profile';
import OfficeMember from "pages/notaryPage/Office/OfficeMember";
import OfficeShareClient from "pages/notaryPage/Office/OfficeShareClient";
import NotFound from 'pages/publicPage/NotFound';
import NoOfficePage from "pages/notaryPage/Office/NoOfficePage";
import RegisterOfficeForm from "components/Office/RegisterOfficeForm";
import Login from "pages/publicPage/Authentification/Login";
import Register from "pages/publicPage/Authentification/Register";

/* Layouts */
import { RootLayout } from "layouts/RootLayout";
import FreeUserLayout from '../layouts/FreeUserLayout'
import ClientLayout from '../layouts/ClientLayout';
import NotaryLayout from '../layouts/NotaryLayout';
import UnknowLayout from '../layouts/UnknowLayout';

/** lazy Import to load data  **/
const FAQ = lazy(() => import("pages/publicPage/FAQ/FAQ"));
const MyDocuments = lazy(() => import("pages/clientPage/Documents/MyDocuments"));
const AskNewDocument = lazy(() => import("pages/clientPage/Documents/AskNewDocument"));
const SearchNotary = lazy(() => import("pages/simpleUser/SearchNotary/SearchNotary"));
const MyClients = lazy(() => import("pages/notaryPage/MyClients/MyClients"));
const ClientProfilePage = lazy(() => import("pages/notaryPage/MyClients/ClientProfilePage"));
const CalendarView = lazy(() => import("pages/commonClientNotary/Rdv/CalendarView"));
const OfficeHome = lazy(() => import("pages/notaryPage/Office/OfficeHome"));
const Chat = lazy(() => import("pages/clientPage/Chat/Chat"));
import HomePageSelector from "layouts/HomePageSelector";
import OfficeMemberInvite from "pages/notaryPage/Office/OfficeMemberInvite";
import BecomeNotary from "pages/simpleUser/BecomeNotary";
import OfficeInCreation from "pages/notaryPage/Office/OfficeInCreation";


export const GlobalRouter = createBrowserRouter([
    {
        path: "/",
        id: "App",
        element: <RootLayout />,
        children: [
            {
                index: true,
                element: <HomePageSelector />,
            },
            {
                path: "about-us",
                element: <AboutUs />,
            },
            {
                path: "plans",
                element: <Plans />,
            },
            {
                path: "faq",
                element: <FAQ />,
            },
            {
                path: "articles",
                element: <Articles />,
            },
            {
                path: "rechercher-notaire/*",
                element: <FreeUserLayout />,
                children: [
                    {
                        path: "",
                        element: <SearchNotary />,
                    }
                ]
            },
            {
                path: "devenir-notaire/*",
                element: <FreeUserLayout />,
                children: [
                    {
                        path: "",
                        element: <BecomeNotary />,
                    }
                ]
            },
            {
                path: "auth/*",
                element: <UnknowLayout />,
                children: [
                    {
                        path: "login",
                        element: <Login />,
                    },
                    {
                        path: "register",
                        element: <Register />
                    },
                    {
                        path: "forgotten",
                        element: <ForgotPassword />,
                    },
                    {
                        path: "reset-password/:token",
                        element: <ResetPassword />,
                    },
                    {
                        path: "*",
                        element: <NotFound />,
                    },
                ],
            },
            {
                path: "user/*",
                element: <FreeUserLayout />,
                children: [
                    {
                        path: "profile",
                        element: <Profile />,
                    },
                    {
                        path: "*",
                        element: <NotFound />,
                    },
                ],
            },
            {
                path: "notary/*",
                element: <NotaryLayout />,
                children: [
                    {
                        path: "profile",
                        element: <Profile />,
                    },
                    {
                        path: "mes-rendez-vous",
                        element: <CalendarView />,
                    },
                    {
                        path: "mes-clients",
                        element: <MyClients />,
                    },
                    {
                        path: "chat",
                        element: <Chat />,
                    },
                    {
                        path: "profil-client/:clientId",
                        element: <ClientProfilePage />,
                    },
                    {
                        path: "office-invite/:uuid",
                        element: <OfficeMemberInvite />,
                    },
                    {
                        path: "office/*",
                        element: <NotaryLayout />,
                        children: [
                            {
                                path: "home",
                                element: <OfficeHome />,
                            },
                            {
                                path: "member",
                                element: <OfficeMember />,
                            },
                            {
                                path: "share",
                                element: <OfficeShareClient />,
                            },
                            {
                                path: "in-creation",
                                element: <OfficeInCreation />,
                            },
                            {
                                path: "no/*",
                                children: [
                                    {
                                        path: "home",
                                        element: <NoOfficePage />,
                                    },
                                    {
                                        path: "register-office",
                                        element: <RegisterOfficeForm />,
                                    },
                                    {
                                        path: "*",
                                        element: <NotFound />,
                                    },
                                ],
                            },
                            {
                                path: "*",
                                element: <NotFound />,
                            },
                        ]
                    },
                    {
                        path: "*",
                        element: <NotFound />,
                    },
                ],
            },
            {
                path: "client/*",
                element: <ClientLayout />,
                children: [
                    {
                        path: "mes-rendez-vous",
                        element: <CalendarView />,
                    },
                    {
                        path: "profile",
                        element: <Profile />,
                    },
                    {
                        path: "mes-documents",
                        element: <MyDocuments />,
                    },
                    {
                        path: "demander-un-document",
                        element: <AskNewDocument />,
                    },
                    {
                        path: "chat",
                        element: <Chat />,
                    },
                    {
                        path: "*",
                        element: <NotFound />,
                    },

                ],
            },
            {
                path: "*",
                element: <NotFound />,
            },
        ],
    }
]);

import React, { useEffect, useRef, useState } from "react";
import Trs from "components/Trs/TrsComponent";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import 'styles/OfficeNavbar.scss'

const OfficeNavbar = (props) => {

    const office = useSelector((state) => state.user.office);
    const location = useLocation();
    const navigate = useNavigate();

    const [activeStyle, setActiveStyle] = useState({ width: 0, left: 0 });

    const homeRef = useRef(null);
    const memberRef = useRef(null);
    const shareRef = useRef(null);

    useEffect(() => {
        let activeElement = null;
        if (location.pathname === '/notary/office/home') {
            activeElement = homeRef.current;
        } else if (location.pathname === '/notary/office/member') {
            activeElement = memberRef.current;
        } else if (location.pathname === '/notary/office/share') {
            activeElement = shareRef.current;
        }

        if (activeElement) {
            setActiveStyle({
                width: activeElement.offsetWidth,
                left: activeElement.offsetLeft,
            });

        }
    }, [location]);

    return (
        <div className="container relative">
            <p className="w-full text-4xl font-medium">{office ? office.name : ''}</p>
            <div className="overflow-hidden py-2 mt-8 mb-2 border-b-[2px] border-grey">
                <ul className="flex space-x-16 ml-5 mb-2 relative">
                    <li ref={homeRef}>
                        <a
                            className="block text-lg font-medium text-black text-center hover:cursor-pointer"
                            onClick={() => {
                                navigate('/notary/office/home');
                            }}
                        >
                            <Trs jsonRef="office_side_home" />
                        </a>
                    </li>
                    <li ref={memberRef}>
                        <a
                            className="block text-lg font-medium text-black text-center hover:cursor-pointer"
                            onClick={() => {
                                navigate('/notary/office/member');
                            }}
                        >
                            <Trs jsonRef="office_side_member" />
                        </a>
                    </li>
                    <li ref={shareRef}>
                        <a
                            className="block text-lg font-medium text-black text-center hover:cursor-pointer"
                            onClick={() => {
                                navigate('/notary/office/share');
                            }}
                        >
                            <Trs jsonRef="office_side_share" />
                        </a>
                    </li>
                </ul>

                <div
                    className="absolute ml-5 h-2 bg-primary transition-all rounded-t-lg duration-500 ease-in-out"
                    style={{
                        width: `${activeStyle.width}px`,
                        left: `${activeStyle.left}px`,
                        bottom: '8px',
                    }}
                />
            </div>
        </div>
    );
};

export default OfficeNavbar;

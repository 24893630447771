import { useEffect, useState } from "react";
import { generalLinks } from "./AllNavLinks";
import Trs from "components/Trs/TrsComponent";
import { Link } from "react-router-dom";

export default function NavBarDropdown() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('#NavBardropdown')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  })

  return (
    <div className="relative inline-block text-left">
      <div className="flex text-grey hover:text-white">
        <button
          onClick={toggleDropdown}
          className="text-grey text-base font-medium leading-6 p-2 hover:text-white rounded-lg hover:bg-[rgba(255,255,255,0.25)] "
        >
          <Trs jsonRef="navbar_more" />
          <i
            className={`fa fa-chevron-down ml-2 transition-transform duration-200 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
            aria-hidden="true"
          ></i>
        </button>
      </div>

      {isOpen && (
        <div id="NavBardropdown" className="absolute z-50 right-0 mt-2 rounded-md bg-primary shadow-lg text-right border-2 border-white">
          <ul className="py-2">
            {generalLinks.map((link, index) => (
              <li key={index} className="p-2 rounded-lg text-white">
                <Link
                  to={link.path}
                  className="flex px-4 py-2 font-medium cursor-pointer p-2 rounded-lg hover:bg-[rgba(255,255,255,0.25)] justify-center"
                >
                  <Trs jsonRef={link.jsonRef} />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

import Trs from "components/Trs/TrsComponent";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import apiService from 'service/api';
import 'styles/OfficeMemberInvite.scss'

const OfficeMemberInvite = () => {
    const { uuid } = useParams();
    const csrfToken = useSelector((state) => state.user.csrfToken);
    const [failedRetrive, setFailedRetrive] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!uuid) {
            navigate('/notary/no-invite');
        }
    }, [uuid])

    const handleAccept = async () => {
        try {
            const response = await apiService.post('office/member/accept/' + uuid + "/", {}, csrfToken, true);

            if (response.status !== 200) {
                throw new Error(`Error: Received status code ${response.status}`);
            }

            setSuccess(true);
        } catch (err) {
            setFailedRetrive(true);
        }
    }

    const handleRefuse = async () => {
        try {
            const response = await apiService.delete('office/member/refuse/' + uuid + "/", csrfToken, true);

            if (response.status !== 200) {
                throw new Error(`Error: Received status code ${response.status}`);
            }

            setSuccess(true);
        } catch (err) {
            setFailedRetrive(true);
        }
    }

    return (
        <div className="invite-main">
            <div className="invite-container">
                <h2><Trs jsonRef="office_member_invite_title" /></h2>
                {!failedRetrive && !success &&
                    <React.Fragment>
                        <p><Trs jsonRef="office_member_invite_txt" />.</p>
                        <div className="button-container">
                            <button onClick={handleRefuse} className="button-refuse"><Trs jsonRef="office_member_invite_refuse" /></button>
                            <button onClick={handleAccept} className="button-accept"><Trs jsonRef="basic_accept" /></button>
                        </div>
                    </React.Fragment>
                }
                {failedRetrive &&
                    <p className="error"><Trs jsonRef="office_member_invite_failed" /></p>
                }
                {success &&
                    <p><Trs jsonRef="office_member_invite_success" /></p>
                }
            </div>
        </div>
    )
}

export default OfficeMemberInvite;
import '../../styles/MemberCard.scss';
import { useState } from 'react';
import { useIntersectionObserver } from 'components/utils/UseIntersectionObserver';
import { LinkedIn } from '@mui/icons-material';

export const MemberCard = (
  {
    title,
	description,
	img,
    delay,
	url
  }) => {

    const [isHovered, setIsHovered] = useState(false);

    useIntersectionObserver('.card-background-container', 'open', {threshold: 0.5});

    return (
        <div className='card-background-container' style={{ '--animation-delay': `${delay}ms` }} onMouseLeave={() => setIsHovered(false)}>
            <div className={`card-overlay-container ${isHovered ? 'card-close' : ''}`} onMouseEnter={() => setIsHovered(true)}>
				<div className='card-infos-container'>
					<div className='member-img-container flex justify-center items-center'>
                		<img className='member-card-img' src={img}/>
					</div>
				    <h1 className='member-card-title'>{title}</h1>
				    <p className='member-card-description'>{description}</p>
					<a href={url} target='_blank'>
						<LinkedIn className='linkedIn-icon'/>
					</a>
				</div>
            </div>
        </div>
    );
};

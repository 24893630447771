import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import apiService from 'service/api';
import Trs from 'components/Trs/TrsComponent';
import 'styles/ForgotPassword.scss';
import { Spinner } from 'components/Loader/Spinner';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLading] = useState(false);

  const basic_oops = Trs({ jsonRef: "basic_oops" });
  const email_sent_success_title = Trs({ jsonRef: "email_sent_success_title" });
  const email_sent_success_text = Trs({ jsonRef: "email_sent_success_text" });
  const email_sent_error_text = Trs({ jsonRef: "email_sent_error_text" });
  const forgot_password_title = Trs({ jsonRef: "forgot_password_title" });
  const forgot_password_desc = Trs({ jsonRef: "forgot_password_desc" });
  const forgot_password_email = Trs({ jsonRef: "forgot_password_email" });

  const submitForm = async (event) => {
    event.preventDefault();

    try {
      await apiService.post('accounts/forgotten/', { email });

      Swal.fire({
        icon: 'success',
        title: email_sent_success_title,
        text: email_sent_success_text,
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: basic_oops,
        text: email_sent_error_text,
      });
    } finally {
      setIsLading(false);
    }
  };

  return (
    <div className='w-full h-screen'>
      <div className='h-full flex justify-center items-center px-7 py-1'>
        <div className="flex items-center w-[95%] sm:w-[80%] md:w-[60%] lg:w-[40%] h-3/4 sm:h-4/5 md:h-4/5 bg-white rounded-lg shadow-xl">
          <div className="w-full h-full flex flex-col justify-center items-center">
            <h1 className="text-2xl text-center font-semibold">{forgot_password_title}</h1>
            <p className="text-opacity-50 mt-8 text-center text-base px-5 sm:px-8 md:px-10 lg:px-12">
              {forgot_password_desc}
            </p>
            <div className="w-full mt-12 flex justify-center items-center">
              <form
                className="w-full flex flex-col items-center gap-7"
                onSubmit={submitForm}
              >
                <div className="flex flex-col w-3/5">
                  <label htmlFor="email" className="py-4 font-medium">
                    {forgot_password_email}
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="rounded-md border border-gray-300 p-2 w-full focus:ring-0 focus:outline-none"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="w-full mt-8 flex justify-center">
                  <button
                    onClick={submitForm}
                    className="w-2/5 mt-3 cursor-pointer font-medium text-sm p-3 bg-primary rounded-md text-white"
                  >
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      <Trs jsonRef="forgot_password_send" />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ForgotPassword;
import React from 'react';
import { css } from '@emotion/react';
import { BeatLoader } from 'react-spinners';

const Loader = ({ loading }) => {
  return (
    <div>
      <BeatLoader color={'#123abc'} loading={loading} />
    </div>
  );
};

export default Loader;

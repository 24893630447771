import React, { useState, useEffect } from "react";
import {
    Box,
    IconButton,
    Modal,
    Typography,
    List,
    ListItem,
    ListItemText,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    Tooltip,
    Button // Importation de Button pour le bouton temporaire
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import apiService from "service/api";
import { useSelector } from 'react-redux';
import Trs from 'components/Trs/TrsComponent';

const style = {
    position: 'absolute',
    top: '80px',
    right: '10px',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '8px',
};

const listStyle = {
    maxHeight: '300px',  // Limite la hauteur à 300px
    overflowY: 'auto',   // Ajoute un défilement vertical si le contenu dépasse la hauteur
};

function NotificationZone() {
    const csrfToken = useSelector((state) => state.user.csrfToken);
    const [notifications, setNotifications] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [sortOrder, setSortOrder] = useState("desc");
    const [selectedType, setSelectedType] = useState("all");
    const [filterValue, setFilterValue] = useState("all");

    useEffect(() => {
        refreshNotifications();
    }, [csrfToken]);

    const formattedNotifications = notifications.map((notification) => {
        const parsedDateTime = new Date(notification.created_at);
        const formattedDate = `${parsedDateTime.getFullYear()}/${String(parsedDateTime.getMonth() + 1).padStart(2, "0")}/${String(parsedDateTime.getDate()).padStart(2, "0")}`;
        const formattedTime = `${String(parsedDateTime.getHours()).padStart(2, "0")}:${String(parsedDateTime.getMinutes()).padStart(2, "0")}`;

        return {
            uid: notification.uid,
            title: notification.title,
            date: formattedDate,
            time: formattedTime,
            type: notification.type
        };
    });

    const refreshNotifications = () => {
        apiService.get('notification/get/', csrfToken, true)
            .then(response => {
                setNotifications(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const openModal = () => {
        setModalIsOpen(true);
        refreshNotifications();
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
    };

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterValue(value);
        setSelectedType(value);
    };

    const handleDeleteNotification = (uid) => {
        try {
            apiService.delete('notification/delete/', csrfToken, true, { uid: uid })
                .then(response => {
                    setNotifications(notifications.filter(notification => notification.uid !== uid));
                })
                .catch(error => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const handleAddNotification = () => {
        try {
            const newNotification = {
                title: 'Notification de test',
                content: 'Ceci est une notification de test.',
                type: 'OTH'
            };

            apiService.post('notification/add/', newNotification, csrfToken, true)
                .then(response => {
                    setNotifications([response.data, ...notifications]);  // Ajoute la nouvelle notification à la liste
                })
                .catch(error => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const filteredNotifications = formattedNotifications.filter((notification) => {
        return selectedType === "all" || notification.type === selectedType;
    });

    const labelTsr = Trs({ jsonRef: "notification_zone_modal_label" });
    const notification_zone_all = Trs({ jsonRef: "notification_zone_all" });
    const notification_zone_oth = Trs({ jsonRef: "notification_zone_oth" });
    const notification_zone_mal = Trs({ jsonRef: "notification_zone_mal" });
    const notification_zone_upd = Trs({ jsonRef: "notification_zone_upd" });
    const notification_zone_inv = Trs({ jsonRef: "notification_zone_inv" });
    const notification_zone_del = Trs({ jsonRef: "notification_zone_del" });

    const sortedNotifications = sortOrder === "asc"
        ? [...filteredNotifications].sort((a, b) => a.date.localeCompare(b.date))
        : [...filteredNotifications].sort((a, b) => b.date.localeCompare(a.date));

    return (
        <Box>
            <Tooltip title="Notifications">
                <IconButton color="inherit" onClick={openModal}>
                    <NotificationsIcon style={{ color: 'white' }} />
                </IconButton>
            </Tooltip>
            <Modal
                open={modalIsOpen}
                onClose={closeModal}
                aria-labelledby={labelTsr}
            >
                <Box sx={style}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" component="h2">
                            <Trs jsonRef="notification_zone_modal_title" />
                        </Typography>
                        <IconButton onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    {/* Bouton temporaire pour ajouter une notification brute */}
                    {/* <Button variant="contained" color="primary" onClick={handleAddNotification} sx={{ mb: 2 }}>
                        Ajouter Notification
                    </Button> */}
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="filter-label"><Trs jsonRef="notif_filter_per_type" /></InputLabel>
                        <Select
                            labelId="filter-label"
                            value={filterValue}
                            label={<Trs jsonRef="notif_filter_per_type" />}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="all">{notification_zone_all}</MenuItem>
                            <MenuItem value="OTH">{notification_zone_oth}</MenuItem>
                            <MenuItem value="MAL">{notification_zone_mal}</MenuItem>
                            <MenuItem value="UPD">{notification_zone_upd}</MenuItem>
                            <MenuItem value="INV">{notification_zone_inv}</MenuItem>
                            <MenuItem value="DEL">{notification_zone_del}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="sort-label"><Trs jsonRef="notif_tri_order" /></InputLabel>
                        <Select
                            labelId="sort-label"
                            value={sortOrder}
                            label={<Trs jsonRef="notif_tri_order" />}
                            onChange={handleSortOrderChange}
                        >
                            <MenuItem value="desc"><Trs jsonRef="notif_more_recent" /></MenuItem>
                            <MenuItem value="asc"><Trs jsonRef="notif_more_ancient" /></MenuItem>
                        </Select>
                    </FormControl>
                    <List sx={listStyle}>
                        {sortedNotifications.map((formattedNotification, index) => (
                            <ListItem key={formattedNotification.uid || index} secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteNotification(formattedNotification.uid)}>
                                    <DeleteIcon />
                                </IconButton>
                            }>
                                <ListItemText
                                    primary={formattedNotification.title}
                                    secondary={`${formattedNotification.date} ${formattedNotification.time}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Modal>
        </Box>
    );
}

export default NotificationZone;

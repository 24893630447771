import React, { useState, useRef, useEffect } from "react";
import { Progress, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import "styles/PasswordStrengthMeter.scss";
import Trs from 'components/Trs/TrsComponent';
import ProgressBar from "./progressBar";

const PasswordStrengthMeter = ({ password, isPopoverOpen }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef(null);

  const toggle = () => setPopoverOpen(!popoverOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopoverOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const testPassword = () => {
    const requirements = [
      {
        message: <Trs jsonRef="password_strength_length" />,
        isMet: password.length >= 8,
      },
      {
        message: <Trs jsonRef="password_strength_uppercase" />,
        isMet: /[A-Z]/.test(password),
      },
      {
        message: <Trs jsonRef="password_strength_lowercase" />,
        isMet: /[a-z]/.test(password),
      },
      {
        message: <Trs jsonRef="password_strength_digit" />,
        isMet: /\d/.test(password),
      },
      {
        message: <Trs jsonRef="password_strength_special_char" />,
        isMet: /\W/.test(password),
      },
    ];

    const score = requirements.reduce(
      (acc, req) => acc + (req.isMet ? 1 : 0),
      0
    );

    return { score, requirements };
  };

  const { score, requirements } = testPassword();

  const getColor = (score) => {
    if (score < 2) return "red"; // rouge
    if (score < 4) return "orange"; // orange
    if (score < 5) return "blue"; // blue
    return "green"; // vert
  };

  return (
    <div className="w-full h-full">
      <ProgressBar id="PopoverFocus" bgColor={getColor(score)} completed={(score / 5) * 100} />
     {/* <Progress multi id="PopoverFocus">
        <Progress
          bar
          color={getColor(score)}
          value={(score / 5) * 100}
          max={100}
        />
      </Progress>
      <Popover
        placement="right"
        isOpen={isPopoverOpen}
        innerRef={popoverRef}
      >
        <PopoverHeader><Trs jsonRef="password_strength_requirements" /></PopoverHeader>
        <PopoverBody style={{ maxWidth: "400px" }}>
          <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            {requirements.map((req, index) => (
              <li
                key={index}
                style={{
                  color: req.isMet ? "green" : "black",
                  marginBottom: "10px",
                  fontSize: "14px",
                }}
              >
                {req.message}
              </li>
            ))}
          </ul>
        </PopoverBody>
      </Popover>
      */}
    </div>
  );
};

export default PasswordStrengthMeter;

import axios from "axios";
import config from "core/config";


class ApiService {

    baseUrl = config.djangoUrl;
    baseWsUrl = config.djangoWsUrl;

    setLoading = () => { }; // Placeholder for setting loading state

    setLoaderCallback(callback) {
        this.setLoading = callback;
    }

    simulateDelay(responsePromise, delay = 2000) {
        return new Promise((resolve) => {
            setTimeout(() => resolve(responsePromise), delay);
        });
    }

    get(url, token, needAuth = false) {
        if (needAuth) {
            return axios.get(this.baseUrl + url, {
                withCredentials: true,
                headers: {
                    'X-CSRFToken': token,
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
        } else {
            return axios.get(this.baseUrl + url, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        }
    }

    async handleRequest(requestFunc) {
        const loaderDelay = 500; // temps d'attente avant d'afficher le loader, afin d'éviter les clignotements
        let loaderTimeout = null;

        try {
            loaderTimeout = setTimeout(() => {
                this.setLoading(true);
            }, loaderDelay);

            const responsePromise = requestFunc();
            // decommenter la ligne suivante pour simuler le delay
            // const response = await this.simulateDelay(responsePromise, 3000); // Change delay to 3000ms for testing

            const response = await responsePromise;

            clearTimeout(loaderTimeout);

            return response;
        } finally {
            clearTimeout(loaderTimeout);
            this.setLoading(false);
        }
    }

    async post(url, data, token, needAuth = false) {
        const headers = needAuth
            ? {
                'X-CSRFToken': token,
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }
            : {
                'Content-Type': 'application/json',
            };

        return this.handleRequest(() =>
            axios.post(this.baseUrl + url, JSON.stringify(data), {
                withCredentials: needAuth,
                headers,
            })
        );
    }

    postwFile(url, data, token, needAuth = false) {
        if (needAuth) {
            return axios.post(this.baseUrl + url, data, {
                withCredentials: true,
                headers: {
                    'X-CSRFToken': token,
                    'Authorization': 'Bearer ' + token,
                },
            });
        } else {
            return axios.post(this.baseUrl + url, data);
        }
    }

    async put(url, data, token, needAuth = false) {
        const headers = {
            'X-CSRFToken': token,
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        };

        return this.handleRequest(() =>
            axios.put(this.baseUrl + url, JSON.stringify(data), {
                withCredentials: true,
                headers,
            })
        );
    }

    async delete(url, token, needAuth = false, data = null) {
        const config = {
            withCredentials: needAuth,
            headers: needAuth
                ? {
                    'X-CSRFToken': token,
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
                : {
                    'Content-Type': 'application/json',
                },
        };

        if (data) {
            config.data = data;
        }

        return this.handleRequest(() => axios.delete(this.baseUrl + url, config));
    }

    getBaseWsUrl() {
        return this.baseWsUrl;
    }
}

export default new ApiService();

import Trs from 'components/Trs/TrsComponent';
import React, { useState } from 'react';
import 'styles/OfficeSharedForm.scss'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import Swal from 'sweetalert2';

const SharedFormControl = styled(FormControl)({
    margin: '8px',
    minWidth: '30vw',
});

const OfficeSharedForm = ({ onClose, onShare, memberList, clientList }) => {
    const [selectMember, setSelectedMember] = useState(null);
    const [selectClient, setSelectedClient] = useState(null);
    const [errors, setErrors] = useState({});
    const emptyMemberFieldError = Trs({ jsonRef: "office_shared_client_member_form_empty" });
    const emptyClientFieldError = Trs({ jsonRef: "office_shared_client_client_form_empty" });

    const validateFormData = () => {
        const newErrors = {};

        if (selectMember === null || selectMember == undefined) {
            newErrors.selectMember = emptyMemberFieldError;
        }

        if (selectClient === null || selectClient == undefined) {
            newErrors.selectClient = emptyClientFieldError;
        }
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleShare = () => {
        const isValid = validateFormData();

        if (isValid) {
            onShare({ id: selectClient.id, name: selectClient.user_name, email: selectClient.email, with: selectMember });
            setSelectedMember(null)
            setSelectedClient(null)
            setErrors({});
            onClose();
        }
    };

    const handleMemberChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedMember(selectedValue === 'default' ? null : selectedValue);
    };

    const handleClientChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedClient(selectedValue === 'default' ? null : selectedValue);
    };

    return (
        <div className='shared-form-container'>
            <div className='shared-form-block'>
                <h2><Trs jsonRef="office_shared_form_title" /></h2>
                <br />
                <div className='shared-form-dropdown-container'>
                    <SharedFormControl>
                        <InputLabel id="client-dropdown-label"><Trs jsonRef="office_shared_form_select_client_label" /></InputLabel>
                        <br />
                        <Select
                            labelId="client-dropdown-label"
                            id="client-dropdown"
                            value={selectClient ? selectClient : 'default'}
                            onChange={handleClientChange}
                        >
                            <MenuItem value="default" disabled>
                                <Trs jsonRef="office_shared_form_select_client_default" />
                            </MenuItem>
                            {clientList.map((client, index) => (
                                <MenuItem key={index} value={client}>
                                    {client.email}
                                </MenuItem>
                            ))}
                        </Select>
                    </SharedFormControl>
                    {errors.selectClient && <span className="error">{errors.selectClient}</span>}
                </div>
                <div className='shared-form-dropdown-container'>
                    <SharedFormControl>
                        <InputLabel id="member-dropdown-label"><Trs jsonRef="office_shared_form_select_member_label" /></InputLabel>
                        <br />
                        <Select
                            labelId="member-dropdown-label"
                            id="member-dropdown"
                            value={selectMember ? selectMember : 'default'}
                            onChange={handleMemberChange}
                        >
                            {memberList.length > 0 &&
                                <MenuItem value="default" disabled>
                                    <Trs jsonRef="office_shared_form_select_member_default" />
                                </MenuItem>
                            }
                            {memberList.map((member, index) => (
                                <MenuItem key={index} value={member}>
                                    {member.email}
                                </MenuItem>
                            ))}
                            {memberList.length == 0 &&
                                <MenuItem value="default" disabled>
                                    <Trs jsonRef="office_shared_form_select_no_member" />
                                </MenuItem>
                            }
                        </Select>
                    </SharedFormControl>
                    {errors.selectMember && <span className="error">{errors.selectMember}</span>}
                </div>
                <div className='button-container'>
                    <button className='cancel' onClick={onClose}><Trs jsonRef="office_shared_form_cancel" /></button>
                    <button className='confirm' onClick={handleShare}><Trs jsonRef="office_shared_form_share" /></button>
                </div>
            </div>
        </div>
    );
};

export default OfficeSharedForm;

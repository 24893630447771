import React, { useState, useEffect } from 'react';
import Trs from "components/Trs/TrsComponent";
import InputWithLabel from "components/Input/InputWithLabel";
import ButtonWithLabel from "components/Input/ButtonWithLabel";
import AppointmentRequest from 'components/AppointmentRequest/AppointmentRequest';
import api from 'service/api'
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

const CertificationContent = () => {

	const csrfToken = useSelector((state) => state.user.csrfToken);
	const [isVerified, setIsVerified] = useState(null);
	const [showPopup, setShowPopup] = useState(false);

	const basic_error = Trs({ jsonRef: "basic_error" });
	const basic_success_title = Trs({ jsonRef: "basic_success_title" });
	const appointment_success_text = Trs({ jsonRef: "appointment_success_text" });
	const appointment_error_text = Trs({ jsonRef: "appointment_error_text" });
	const error_when_loading_datas = Trs({ jsonRef: "error_when_loading_datas" });
	const certif_renew = Trs({ jsonRef: "profile_certf_renew" });
	const certif_demand = Trs({ jsonRef: "profile_certf_request" });

	const handleOpenPopup = () => {
		setShowPopup(true);
	};

	const handleClosePopup = () => {
		setShowPopup(false);
	};

	const handleSubmitForm = (formData) => {
		try {
			api.post('planning/ask/', formData, csrfToken, true)
				.then((response) => {
					Swal.fire({
						icon: 'success',
						title: basic_success_title,
						text: appointment_success_text,
					});
				})
				.catch((error) => {
					Swal.fire({
						icon: 'error',
						title: basic_error,
						text: appointment_error_text,
					});
				});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await api.get("accounts/user/", csrfToken, true);
				const userData = response.data.user;
				setIsVerified(response.data.user.verified);
			} catch (error) {
				console.error(error_when_loading_datas, error);
			}
		};

		fetchData();
	}, []);

	const formatDate = (dateString) => {
		const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
		const date = new Date(dateString);
		return date.toLocaleDateString('fr-FR', options);
	};

	return (
		<div className="certification-container">
			<div className="certif-content">
				{isVerified ? (
					<>
						<p><Trs jsonRef={'profile_certf_certified'}/>{formatDate(isVerified)} </p>
						<ButtonWithLabel label={<Trs jsonRef={'profile_certf_renew'}/>} onClick={handleOpenPopup} />
						{showPopup && (
							<AppointmentRequest
								preFilledReason={certif_renew}
								onSubmit={handleSubmitForm}
								onClose={handleClosePopup}
							/>
						)}
					</>
				) : (
					<>
						<p><Trs jsonRef={'profile_certf_not_certified'}/></p>
						<div className='certification-button'>
							<ButtonWithLabel label={<Trs jsonRef={'profile_certf_request'}/>} onClick={handleOpenPopup} />
						</div>
						{showPopup && (
							<AppointmentRequest
								preFilledReason={certif_demand}
								onSubmit={handleSubmitForm}
								onClose={handleClosePopup}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default CertificationContent;
